import { createMuiTheme } from "@material-ui/core";
import { Shadows } from "@material-ui/core/styles/shadows";

const theme = createMuiTheme({
  shadows: Array(25).fill("none") as Shadows,
  overrides: {
    MuiToolbar: {
      root: {
        "@media (min-width: 0px)": {
          paddingLeft: 0,
          paddingRight: 0
        }
      },
      gutters: {
        "@media (min-width: 0px)": {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    },
    MuiButton: {
      root: {
        "&$disabled": {
          opacity: 0.4,
          color: "inherit"
        },
        borderRadius: "unset",
        color: "#000",
        textTransform: "capitalize"
      }
    },
    MuiPaper: {
      root: {
        borderRadius: 0
      },
      rounded: {
        borderRadius: 0
      }
    }
  }
});

export default theme;
