import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./components/App";
import store from "./store/index";
import "./styles/main.sass";
import theme from "./styles/theme";
import LoadingDocument from "./components/LoadingDocument";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

declare global {
  interface Window { _env_: any; }
}

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route
            exact
            path={[
              "/document/:provider/:providerId",
              "/document/:id",
              "/document/",
              "/"
            ]}
            component={LoadingDocument}
          ></Route>
          <Route exact path="/:id" component={App} ></Route>
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
