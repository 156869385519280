import React from "react";
import { Box, Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  statLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    lineHeight: 1,
    position: "absolute",
    top: 0,
    left: 0
  },
  statValue: {
    marginTop: 20
  },
  root: {
    height: 48,
    minWidth: 100,
    marginRight: 16,
    marginLeft: 16
  }
}));

interface SurferMenuStatItemProps {
  label: string;
  value: string | JSX.Element;
  width?: number;
}

const SurferMenuStatItem = ({
  label,
  value,
  width
}: SurferMenuStatItemProps) => {
  const classes = useStyles({});
  return (
    <Box className={classes.root} position="relative" width={width}>
      <Typography
        noWrap
        component="p"
        variant="caption"
        className={classes.statLabel}
      >
        {label}
      </Typography>
      {typeof value === "string" ? (
        <Typography className={classes.statValue}>{value}</Typography>
      ) : (
        <Box className={classes.statValue}>{value}</Box>
      )}
    </Box>
  );
};

export default SurferMenuStatItem;
