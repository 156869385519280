import React, { MouseEvent, useCallback, SyntheticEvent, memo } from 'react';
import { Box } from '@material-ui/core';
import { SpeakerItem, TimeLineItem } from '../store/types/documentTypes';
import { useSelector, useDispatch } from 'react-redux';
import { selectDocumentEditorTimeLine } from '../store/selectors/documentSelectors';
import { setDocumentPartial } from '../store/actions/documentActions';
import { EditorTimelineProperties } from '@newtral/editor-svc-client/esm';

interface SpeakerProps {
    speaker: SpeakerItem;
    hash: string;
    add: (event: MouseEvent<Element, globalThis.MouseEvent>) => void;
    remove: (event: MouseEvent<Element, globalThis.MouseEvent>) => void;
    renderChanges: (paragraphs:TimeLineItem, save:boolean) => void;
}

const Speaker = ({speaker, hash, add, remove, renderChanges}: SpeakerProps)=> {
    const selectEditorTimeLine:TimeLineItem = useSelector(selectDocumentEditorTimeLine);
    const dispatch = useDispatch();
    
    const changeText = useCallback((event:SyntheticEvent) => {
        let changed: boolean = false;
        selectEditorTimeLine.forEach((paragraph:EditorTimelineProperties) => {
            Object.keys(paragraph).forEach((sentence)=>{
                if(paragraph[Number(sentence)].speaker === speaker.value){
                    if(paragraph[Number(sentence)].speaker  !== event.currentTarget.textContent){
                        changed = true;
                        paragraph[Number(sentence)].speaker = event.currentTarget.textContent;
                    }
                }
            });
        });
        if(changed){
            dispatch(setDocumentPartial({ editorData: { timeline: selectEditorTimeLine } }));
            renderChanges(selectEditorTimeLine, true);
        }
    }, [dispatch, selectEditorTimeLine, speaker.value, renderChanges]);
    
    return (
        <Box 
            className="speaker" 
            data-type="speaker" 
            data-hash={hash} 
            data-time={speaker.time} 
            data-speaker={speaker.value} 
            contentEditable 
            suppressContentEditableWarning={true} 
            onBlur={changeText}
        >
            {speaker.value}
        </Box>
    );
};

export default memo(Speaker);