import DomHelper from './domHelper';
import { FooterRefObject } from '../components/Footer';

export default class SpotlightHelper {
  static cleanSpotlightedText(container: Element | Document = document) {
    container.querySelectorAll('.marked-strong').forEach(e => e.classList.remove('marked-strong'));
  }

  static spotlightMarkWithNext(
    marks: Element[],
    getNextMarks: Function,
    getNextSentence: Function,
    setKaraokeTimeout: Function,
    playbackRate: number,
    videoController: FooterRefObject,
    setCurrentMarks: Function,
    stopCallback: Function,
    setCurrentCard: Function
  ): void {
    if (!!marks) {
      setCurrentMarks(marks);
      const initialMark = marks[0];
      let timeout = 0;
      let nextSentence = null;
      if (!!initialMark) {
        setCurrentCard(initialMark.getAttribute('data-mark'));
        DomHelper.scrollToElement(initialMark);
        if (videoController) {
          videoController.seek(parseFloat(initialMark.getAttribute('data-time')));
        }
        setTimeout(() => {
          DomHelper.scrollToElement(document.querySelector(`[data-id="${initialMark.getAttribute('data-mark')}"]`));
        }, 1000);
        nextSentence = getNextSentence(marks[marks.length - 1].closest('[data-type="sentence"]'));
        timeout = (this.getTimeDiffBetweenElements(initialMark, nextSentence) * 1000) / playbackRate;
      }
      const nextMarks = getNextMarks(marks);
      const timeoutId = this.spotlightElementsWithNext(marks, timeout, () =>
        this.spotlightMarkWithNext(
          nextMarks,
          getNextMarks,
          getNextSentence,
          setKaraokeTimeout,
          playbackRate,
          videoController,
          setCurrentMarks,
          stopCallback,
          setCurrentCard
        )
      );
      setKaraokeTimeout(timeoutId);
      if (!(nextMarks && nextSentence)) {
        setTimeout(() => {
          stopCallback();
          setCurrentMarks([]);
          setCurrentCard('');
        }, timeout);
      }
    }
  }

  static spotlightSentenceWithNext(
    time: number,
    sentence: Element,
    getNextSentence: Function,
    setKaraokeTimeout: Function,
    playbackRate: number
  ): void {
    let timeoutId: NodeJS.Timeout;
    DomHelper.scrollToElement(sentence);
    const nextSentence = getNextSentence(sentence);
    if (nextSentence) {
      const timeout = (this.getTimeDiffToElement(time, nextSentence) * 1000) / playbackRate;
      timeoutId = this.spotlightElementsWithNext([sentence], timeout, () =>
        this.spotlightSentenceWithNext(
          parseFloat(nextSentence.getAttribute('data-time')),
          nextSentence,
          getNextSentence,
          setKaraokeTimeout,
          playbackRate
        )
      );
    } else {
      timeoutId = this.spotlightElementsWithClear([sentence]);
    }
    setKaraokeTimeout(timeoutId);
  }

  private static getTimeDiffToElement(time: number, element2: Element): number {
    const nextElementTime = parseFloat(element2.getAttribute('data-time'));
    return Math.abs(nextElementTime - time);
  }

  private static getTimeDiffBetweenElements(element1: Element, element2: Element): number {
    const elementTime = parseFloat(element1.getAttribute('data-time'));
    const nextElementTime = parseFloat(element2.getAttribute('data-time'));
    return Math.abs(nextElementTime - elementTime);
  }

  static spotlightMarks(marks: Element[]): void {
    this.cleanSpotlightedText();
    const initialMark = marks[0];
    if (initialMark) {
      DomHelper.scrollToElement(initialMark);
      this.spotlightElements(marks);
    }
  }

  private static spotlightElementsWithNext(elements: Element[], timeout: number, next: Function): NodeJS.Timeout {
    return this.spotlightElementsWithClear(elements, timeout, next);
  }

  private static spotlightElementsWithClear(elements: Element[], timeout: number = 2000, timeoutCallback: Function = null): NodeJS.Timeout {
    if (elements.length > 0) {
      this.spotlightElements(elements);
      return this.timedCleanSpotlightFromElements(elements, timeout, timeoutCallback);
    }
  }

  private static spotlightElements(elements: Element[]): void {
    this.highlightElements(elements, this.highlightElementsAdd);
  }

  private static timedCleanSpotlightFromElements(elements: Element[], timeout: number = 2000, timeoutCallback: Function = null) {
    return setTimeout(() => {
      if (timeoutCallback) {
        timeoutCallback();
      }
      this.highlightElements(elements, this.highlightElementsRemove);
    }, timeout);
  }

  private static highlightElements(elements: Element[], action: Function): void {
    if (elements[0].getAttribute('data-type') === 'word') {
      return action(elements);
    }
    elements.forEach(el => {
      const childs = Array.from(el.children);
      action(childs);
    });
  }

  private static highlightElementsAdd(elements: Element[]): void {
    elements.forEach(e => e.classList.add('marked-strong'));
  }

  private static highlightElementsRemove(elements: Element[]): void {
    elements.forEach(e => e.classList.remove('marked-strong'));
  }
}
