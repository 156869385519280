import React, { MouseEvent, memo, useMemo } from 'react';
import Sentence from './Sentence';
import Box from '@material-ui/core/Box';
import { TranscriptionItem, SentencesItem, TimeLineItem } from '../store/types/documentTypes';
import { TimelineSpeaker } from '@newtral/editor-svc-client/esm';

interface ParagraphProps {
    text: SentencesItem;
    speaker: TranscriptionItem;
    hash: string;
    add: (event: MouseEvent<Element, globalThis.MouseEvent>) => void;
    remove: (event: MouseEvent<Element, globalThis.MouseEvent>) => void;
    mouseUp: (event: MouseEvent<Element, globalThis.MouseEvent>) => void;
    playSentence: (start:number, end:number) => void;
    renderChanges: (paragraphs:TimeLineItem, save:boolean) => void;
}

const Paragraph = ({text, speaker, hash, add, remove, mouseUp, playSentence, renderChanges}: ParagraphProps)=> {
    const getSentences = (text:SentencesItem) => {
        return text.map((sentence:TimelineSpeaker) => {
            return <Sentence 
                key={`sentence_${sentence.time}_${speaker.value}`} 
                sKey={sentence.time} 
                text={sentence.words} 
                hash={hash} 
                speaker={speaker.value} 
                playSentence={playSentence}
                renderChanges={renderChanges}
            />
        });
    };

    const RenderSentences = (text:SentencesItem) => {
        return useMemo(() => getSentences(text), [text]);
    }; 

    const mouseUpHandler = (event: MouseEvent) => {
        mouseUp(event);
    };

    return (
        <Box 
            className="paragraph" 
            data-type="paragraph" 
            data-hash={hash} 
            data-time={speaker.time} 
            data-speaker={speaker.value} 
            contentEditable={false} 
            suppressContentEditableWarning={true} 
            onMouseUp={mouseUpHandler}
        >
            {RenderSentences(text)}
        </Box>
    );
};

export default memo(Paragraph);