import { combineReducers, createStore } from "redux";
import { editor } from "./reducers/editorReducer";
import { surfer } from "./reducers/surferReducer";
import { document } from "./reducers/documentReducer";

import { devToolsEnhancer } from "redux-devtools-extension";

const appReducer = combineReducers({
  editor,
  surfer,
  document
});

const store = createStore(appReducer, devToolsEnhancer({}));

export default store;

export type AppState = ReturnType<typeof appReducer>;
