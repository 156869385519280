import { createSelector } from "reselect";
import { AppState } from "..";
import { ClaimbotAlgorithm } from "../types/editorTypes";
import { MarkedItemInfo } from "@newtral/editor-svc-client/esm";
import { User } from '@newtral/editor-svc-client/esm/types/users';

export const selectEditorIsHightlighting = createSelector(
  (state: AppState) => state.editor.highlight,
  (highlight: boolean) => highlight
);

export const selectEditorClaimbotAlgorithm = createSelector(
  (state: AppState) => state.editor.claimbotAlgorithm,
  (claimbotAlgorithm: ClaimbotAlgorithm) => claimbotAlgorithm
);

export const selectEditorCurrentCard = createSelector(
  (state: AppState) => state.editor.card,
  (card: string) => card
);

export const selectEditorCurrentMark = createSelector(
  (state: AppState) => state.editor.mark,
  (mark: MarkedItemInfo | null) => mark
);

export const selectEditorCurrentCardEditing = createSelector(
  (state: AppState) => state.editor.editing,
  (editing: string) => editing
);

export const selectEditorDialogAction = createSelector(
  (state: AppState) => state.editor.action,
  (action: string) => action
);

export const selectEditorDialogURL = createSelector(
  (state: AppState) => state.editor.url,
  (url: string) => url
);

export const selectEditorDialogUser = createSelector(
  (state: AppState) => state.editor.user,
  (user: User | {}) => user
);