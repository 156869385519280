import {
  EditorActionTypes,
  SET_HIGHLIGHT_STATUS,
  SET_CLAIMBOT_ALGORITHM,
  EditorState,
  ClaimbotAlgorithm,
  SET_HISTORY,
  SET_CARD,
  SET_MARK,
  SET_CARD_EDITING,
  SET_DIALOG_ACTION,
  SET_DIALOG_URL,
  SET_USER
} from "../types/editorTypes";

export const defaultState: EditorState = {
  highlight: true,
  claimbotAlgorithm: ClaimbotAlgorithm.DATA,
  history: [],
  card: "",
  mark: null,
  editing: null,
  action: null,
  url: "",
  user: {}
};

export function editor(state = defaultState, action: EditorActionTypes) {
  switch (action.type) {
    case SET_HIGHLIGHT_STATUS:
      return {
        ...state,
        highlight: action.highlight
      };
    case SET_CLAIMBOT_ALGORITHM:
      return {
        ...state,
        claimbotAlgorithm: action.claimbotAlgorithm
      };
    case SET_HISTORY:
      return {
        ...state,
        history: action.history
      };
    case SET_CARD:
      return {
        ...state,
        card: action.card
      };
    case SET_MARK:
      return {
        ...state,
        mark: action.mark
      };
    case SET_CARD_EDITING:
      return {
        ...state,
        editing: action.editing
      };
    case SET_DIALOG_ACTION:
      return {
        ...state,
        action: action.action
      };
    case SET_DIALOG_URL:
      return {
        ...state,
        url: action.url
      };
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    default:
      return {
        ...state
      };
  }
}
