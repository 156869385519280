import {
  DocumentState,
  DocumentActionTypes,
  SET_DOCUMENT,
  SET_DOCUMENT_PARTIAL,
  SET_DOCUMENT_HASH
} from "../types/documentTypes";
import _ from "lodash";

export const defaultState: DocumentState = {
  document: null,
  hash: ""
};

// It needs to be any because you do not know exactly what this is going to receive
const mergeCustomizer = (_objValue: any, srcValue: any, key: string) => {
  const keys = ["marks", "timeline", "botmode"];
  if (keys.includes(key) ) {
    return srcValue;
  }
};

export function document(state = defaultState, action: DocumentActionTypes) {
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        document: action.document
      };
    case SET_DOCUMENT_PARTIAL:
      return {
        ...state,
        document: _.mergeWith(
          {},
          state.document,
          action.partialDocument,
          mergeCustomizer
        )
      };
    case SET_DOCUMENT_HASH:
      return {
        ...state,
        hash: action.hash
      };
    default:
      return {
        ...state
      };
  }
}
