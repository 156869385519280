import axios from 'axios';
import { ExtractURLServiceResponseInterface } from '../../interfaces';

export default class ExtractURLApi {
  static async extractURLService(url: String): Promise<ExtractURLServiceResponseInterface> {
    try {
      const response = await axios({
        url: window._env_.REACT_APP_EXTRACT_URL,
        method: 'post',
        data: { url, source: 'editor_web', type: 'article' }
      });
      return response.data;
    } catch (err) {
      return { error: { status: err.response?.status, message: `ExtractURLService: ${err.message}` } };
    }
  }
}
