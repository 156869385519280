import { AppBar, Box, Button, InputBase, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import Autorenew from '@material-ui/icons/Autorenew';
import Delete from '@material-ui/icons/Delete';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import _ from 'lodash';
import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentPartial } from '../store/actions/documentActions';
import { setHighlightStatus, setDialogAction } from '../store/actions/editorActions';
import {
  selectDocument,
  selectDocumentName,
  selectDocumentStatus,
  selectDocumentIdEditorAndProviderData,
  selectDocumentEditorDataMarked
} from '../store/selectors/documentSelectors';
import {
  selectEditorIsHightlighting,
  selectEditorDialogAction,
  selectEditorDialogURL,
  selectEditorDialogUser
} from '../store/selectors/editorSelectors';
import { Status, MarkedItemInfo, Provider } from '@newtral/editor-svc-client/esm';
import { ReactComponent as ClaimbotIcon } from '../styles/icons/claimbot.svg';
import { ReactComponent as HighlightIcon } from '../styles/icons/highlight.svg';
import { ReactComponent as RedoIcon } from '../styles/icons/redo.svg';
import { ReactComponent as UndoIcon } from '../styles/icons/undo.svg';
import { ReactComponent as FinishIcon } from '../styles/icons/finish.svg';
import DropdownButton, { DropdownItem } from './DropdownButton';
import Editor from './Editor';
import VerticalDivider from './VerticalDivider';
import NotificationApi from '../helpers/api/notificationApi';
import { NotificationRequestInterface } from '../interfaces';
import { HightLightTypes, markedItemInfoComparatorByInitial, VideomaAllMetadata } from '../store/types/documentTypes';
import { DialogRefObject } from './Dialog';
import { dialogActions, DialogItem } from '../store/types/editorTypes';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LinkIcon from '@material-ui/icons/Link';
import BackendApi from '../helpers/api/backendApi';
import ExtractURLApi from '../helpers/api/extractUrlApi';

const useStyles = makeStyles((theme: Theme) => ({
  nameContainer: {
    width: '100%'
  },
  name: {
    color: theme.palette.text.primary,
    width: '100%',
    fontSize: '18px',
    fontWeight: 400,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:focus': {
      textOverflow: 'clip'
    }
  },
  finished: {
    backgroundColor: '#1565c0',
    fill: 'white',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1565c0'
    }
  },
  claim: {
    stroke: '#000000',
    fill: '#000000'
  }
}));

interface HeaderProps {
  dialogRef: React.RefObject<DialogRefObject>;
  editorRef: React.RefObject<typeof Editor.WrappedComponent.prototype>;
  deleteDocument: Function;
  isVideo: boolean;
  setUnsavedChanges: Function;
}

const Header = ({ dialogRef, editorRef, deleteDocument, isVideo, setUnsavedChanges }: HeaderProps) => {
  const dispatch = useDispatch();
  const classes = useStyles({});
  // State
  const highlight = useSelector(selectEditorIsHightlighting);
  const document = useSelector(selectDocument);
  const documentName = useSelector(selectDocumentName);
  const documentStatus = useSelector(selectDocumentStatus);
  const dialogAction = useSelector(selectEditorDialogAction);
  const dialogURL = useSelector(selectEditorDialogURL);
  const dialogUser = useSelector(selectEditorDialogUser);
  const { id, name, providerData } = useSelector(selectDocumentIdEditorAndProviderData);
  const marks = useSelector(selectDocumentEditorDataMarked);
  const [documentNameLocal, setDocumentNameLocal] = useState(documentName);

  // Event Handlers
  const handleClaimbotClick = useCallback(() => {
    editorRef.current.markFromData();
  }, [editorRef]);

  const handleHighlightClick = useCallback(() => {
    dispatch(setHighlightStatus(!highlight));
  }, [dispatch, highlight]);

  const handleUndoClick = useCallback(() => {
    editorRef.current.undoHistory();
  }, [editorRef]);

  const handleRedoClick = useCallback(() => {
    editorRef.current.redoHistory();
  }, [editorRef]);

  const handleUnmarkClick = useCallback(() => {
    editorRef.current.unmark();
  }, [editorRef]);

  const dialogDeleteDocument = useCallback(() => {
    const newDialog: DialogItem = {
      action: dialogActions.deleteDocument,
      description: 'Esta acción no se puede deshacer',
      message: '¿Quieres eliminar este documento?'
    };
    dialogRef.current.handleSetDialog(newDialog);
  }, [dialogRef]);

  const handleDeleteClick = useCallback(() => {
    editorRef.current.deleteTextComponents();
    deleteDocument();
  }, [deleteDocument, editorRef]);

  const handleChangeNameDebounced = useCallback(
    _.debounce((event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDocumentPartial({ name: event.target.value }));
      setUnsavedChanges(true);
    }, 1000),
    [setUnsavedChanges, dispatch]
  );

  const handleChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setDocumentNameLocal(event.target.value);
      handleChangeNameDebounced(event);
    },
    [handleChangeNameDebounced]
  );

  const getPinnedSentences = useCallback(() => {
    const claimCards: MarkedItemInfo[] = Object.values(marks)
      .reduce((prev, current) => prev.concat(Object.values(current)), [])
      .sort(markedItemInfoComparatorByInitial);

    return claimCards
      .filter(claim => {
        return claim.highlight === HightLightTypes.pinned;
      })
      .map(claim => {
        return { sentenceId: claim.id, sentence: claim.text };
      });
  }, [marks]);

  const sendNotification = useCallback(async () => {
    const pinnedSentences = getPinnedSentences();

    const metadata = providerData?.metadata as VideomaAllMetadata;
    const videoTitle = metadata?.TITULO || metadata?.titulo || name;
    const personName = metadata?.PERSONAJE?.toString() ?? '';

    const user =
      dialogUser && 'realName' in dialogUser && 'avatar' in dialogUser
        ? {
            name: dialogUser.realName,
            avatar: dialogUser.avatar
          }
        : undefined;

    const notifications: NotificationRequestInterface[] = pinnedSentences.map(pinnedSentence => ({
      kind: 'editor:sentences:pinned',
      videoId: id,
      videoTitle,
      personName,
      pinnedSentence,
      user
    }));

    await NotificationApi.sendSentences(notifications);
  }, [dialogUser, getPinnedSentences, id, name, providerData]);

  const handleFinishClick = useCallback(async () => {
    dispatch(
      setDocumentPartial({
        status: documentStatus === Status.Finished ? Status.InProgress : Status.Finished,
        editorData: { user: dialogUser }
      })
    );
    if (dialogAction === dialogActions.sendSentences) {
      await sendNotification();
    }
    setUnsavedChanges(true);
    setTimeout(() => {
      editorRef.current.save();
    }, 500);
  }, [dispatch, documentStatus, dialogUser, dialogAction, setUnsavedChanges, sendNotification, editorRef]);

  const dialogUserAndSend = useCallback(async () => {
    const pinnedSentences = getPinnedSentences();
    const users = await BackendApi.getUsers();

    let action = dialogActions.selectUser;
    let description = '';
    let message = '';

    if (documentStatus === Status.InProgress) {
      if (pinnedSentences.length > 0) {
        action = dialogActions.dialogSentences;
        description = 'Si pulsas enviar, se enviarán las frases marcadas al canal de Slack';
        message = '¿Quieres enviar las frases marcadas?';
      }

      const newDialog: DialogItem = { action, description, message, users };

      return dialogRef.current.handleSetDialog(newDialog);
    } else {
      await handleFinishClick();
    }
  }, [dialogRef, documentStatus, getPinnedSentences, handleFinishClick]);

  // Derived properties
  const highlightExtraActions: DropdownItem[] = [
    {
      label: 'Desmarcar',
      handleClick: handleUnmarkClick,
      icon: <Autorenew />
    }
  ];

  const highlightActions: DropdownItem[] = [
    {
      label: 'Subrayar',
      handleClick: handleHighlightClick,
      icon: <Box className="highlight-color mark-high" />
    }
  ];

  const handlePasteURLClick = useCallback(async () => {
    try {
      const urlDocument = await BackendApi.getDocumentByProvider(Provider.Medio, encodeURIComponent(dialogURL));
      setDocumentNameLocal(urlDocument.name);
      await editorRef.current.documentFromURLProvider(urlDocument);
    } catch (err) {
      const extract = await ExtractURLApi.extractURLService(dialogURL);
      if (extract == null || extract.error) {
        return editorRef.current.showAlert(extract.error.message, 'error');
      }
      Object.assign(extract.metadata, { text: extract.text });
      setDocumentNameLocal(extract.metadata.title);
      await editorRef.current.documentFromPasteURL(extract, document._id, dialogURL);
    }
  }, [dialogURL, document, editorRef]);

  const dialogPasteURL = useCallback(() => {
    const newDialog: DialogItem = {
      action: 'pasteURL',
      description: 'Añade una url de una noticia para extrear el texto',
      message: 'Pega o escribe la url',
      url: true
    };
    dialogRef.current.handleSetDialog(newDialog);
  }, [dialogRef]);

  const optionsActions: DropdownItem[] = [
    {
      label: 'Pegar URL',
      handleClick: dialogPasteURL,
      icon: <LinkIcon />,
      disabled: isVideo
    }
  ];

  const checkActionFinish = useCallback(() => {
    const actions: string[] = [
      dialogActions.selectUser,
      dialogActions.dialogSentences,
      dialogActions.sendSentences,
      dialogActions.sendSentencesClose
    ];
    return actions.includes(dialogAction) && Object.keys(dialogUser).length > 1;
  }, [dialogAction, dialogUser]);

  useEffect(() => {
    if (dialogAction === dialogActions.deleteDocument) {
      handleDeleteClick();
      dispatch(setDialogAction(null));
    }
    if (dialogAction === dialogActions.pasteURL) {
      handlePasteURLClick();
      dispatch(setDialogAction(null));
    }
    if (checkActionFinish()) {
      handleFinishClick();
      dispatch(setDialogAction(null));
    }
  }, [dispatch, dialogAction, dialogUser, handleDeleteClick, handleFinishClick, handlePasteURLClick, checkActionFinish]);

  return (
    <AppBar className="appbar">
      <Toolbar className="controls">
        <Box className="toolbar-left">
          <VerticalDivider />
          <Button id="claimbot" onClick={handleClaimbotClick}>
            <ClaimbotIcon className={classes.claim} height={24} />
            <Typography variant="caption" component="h2">
              ClaimBot
            </Typography>
          </Button>
          <VerticalDivider />

          <Button id="undo" onClick={handleUndoClick}>
            <UndoIcon height={24} />
            <Typography variant="caption" component="h2">
              Deshacer
            </Typography>
          </Button>

          <Button id="redo" onClick={handleRedoClick}>
            <RedoIcon height={24} />
            <Typography variant="caption" component="h2">
              Rehacer
            </Typography>
          </Button>

          <VerticalDivider />

          <DropdownButton
            className={highlight ? 'button-selected' : null}
            buttonLabel="Subrayar"
            options={highlightActions}
            selectedOption=""
            setSelectedOption={handleHighlightClick}
            icon={<HighlightIcon height={24} />}
            buttonId="highlight"
            extraMenuItems={highlightExtraActions}
            handleClick={handleHighlightClick}
          />

          <VerticalDivider />

          <Button onClick={dialogDeleteDocument}>
            <Delete />
            <Typography variant="caption" component="h2">
              Eliminar
            </Typography>
          </Button>

          <VerticalDivider />

          <DropdownButton
            buttonLabel="Opciones"
            options={optionsActions}
            selectedOption=""
            icon={<MoreVertIcon height={24} />}
            buttonId="options"
            arrow={false}
          />

          <VerticalDivider />
        </Box>
        <Box flexGrow={1} minWidth={0} paddingLeft={2} paddingRight={2}>
          <InputBase
            value={documentNameLocal}
            className={classes.nameContainer}
            inputProps={{ className: classes.name }}
            onChange={handleChangeName}
          />
        </Box>
        <Box className="toolbar-right">
          <VerticalDivider />
          <Button onClick={dialogUserAndSend} className={documentStatus === Status.Finished ? classes.finished : ''}>
            {documentStatus === Status.Finished ? <AssignmentTurnedInIcon height={24} /> : <FinishIcon height={24} />}

            <Typography variant="caption" component="h2">
              {documentStatus === Status.Finished ? 'Finalizado' : 'Finalizar'}
            </Typography>
          </Button>
          <VerticalDivider />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
