import { Box, Theme, Slider, Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDocumentDedicationTime, selectDocumentIdEditorAndProviderData } from '../store/selectors/documentSelectors';
import { makeStyles } from '@material-ui/styles';
import VerticalDivider from './VerticalDivider';
import moment from 'moment';
import SurferMenuStatItem from './SurferMenuStatItem';
import SurferMenuControllers from './SurferMenuControllers';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PauseIcon from '@material-ui/icons/Pause';
import BackendApi from '../helpers/api/backendApi';
import { Action, ActionTime } from '@newtral/editor-svc-client/esm';
import Editor from './Editor';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 60,
    border: '1px solid #e0e0e0'
  },
  slider: {
    color: '#1565c0',
    zIndex: 999
  },
  playbackRateLabel: {
    marginBottom: 5,
    width: 36,
    textAlign: 'center',
    padding: '0 !important'
  },
  controllers: {
    height: '100%',
    backgroundColor: '#e0e0e0'
  },
  stats: {
    height: '100%'
  },
  settings: {
    height: '100%'
  },
  record: {
    float: 'right',
    right: '0px',
    position: 'absolute',
    alignItems: 'center',
    flexFlow: 'row',
    display: 'flex',
    padding: '0 10px',
    backgroundColor: '#e0e0e0',
    height: '60px',
    width: '170px',
    cursor: 'pointer'
  },
  recording: {
    backgroundColor: '#f44336',
    color: '#ffffff',
    fontWeight: 500
  },
  recordButton: {
    color: '#ffffff'
  },
  dedicatedTime: {
    fontSize: '12px',
    padding: '0 10px',
    textAlign: 'center'
  }
}));

interface SurferMenuProps {
  editorRef: React.RefObject<typeof Editor.WrappedComponent.prototype>;
  playbackRate: number;
  changePlaybackRate: (event: ChangeEvent<{}>, value: number | number[]) => void;
  playVideo: () => void;
  pauseVideo: () => void;
  currentTime: number;
  isPlaying: boolean;
  duration: number;
  videoForward: () => void;
  videoRewind: () => void;
}

const formatSecondsFromVideo = (duration: number) => {
  const seconds = moment.duration(duration, 'seconds');
  return moment.utc(seconds.as('milliseconds')).format('HH:mm:ss');
};

const SurferMenu = ({
  editorRef,
  playbackRate,
  changePlaybackRate,
  playVideo,
  pauseVideo,
  currentTime,
  isPlaying,
  duration,
  videoForward,
  videoRewind
}: SurferMenuProps) => {
  const classes = useStyles({});
  const dedicationTime = useSelector(selectDocumentDedicationTime);
  const { id } = useSelector(selectDocumentIdEditorAndProviderData);
  const [recording, setRecording] = useState(false);
  const [hoverRecording, setHoverRecording] = useState(false);

  const getDedicationTime = useCallback(() => {
    const time = dedicationTime != null ? Math.ceil(dedicationTime / 1000 / 60) : 0;
    return `${time} min`;
  }, [dedicationTime]);

  const handleClickRecord = async () => {
    if (recording) {
      await postActionEnd();
      editorRef.current.save();
    } else {
      await postActionStart();
    }
    setRecording(!recording);
  };

  const mouseOverRecording = () => {
    setHoverRecording(true);
  };

  const mouseOutRecording = () => {
    setHoverRecording(false);
  };

  const postActionStart = async () => {
    const payload: ActionTime = {
      videoId: id,
      time: new Date(),
      action: Action.VideoStart
    };
    await BackendApi.postActionTime(payload);
  };

  const postActionEnd = useCallback(async () => {
    const payload: ActionTime = {
      videoId: id,
      time: new Date(),
      action: Action.VideoEnd
    };
    await BackendApi.postActionTime(payload);
  }, [id]);

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <SurferMenuControllers
        isPlaying={isPlaying}
        videoForward={videoForward}
        videoRewind={videoRewind}
        pauseVideo={pauseVideo}
        playVideo={playVideo}
      />
      <Box flexGrow={1} className={classes.stats} display="flex" alignItems="center">
        <SurferMenuStatItem
          width={150}
          label="Velocidad"
          value={
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  className={classes.slider}
                  valueLabelDisplay="auto"
                  value={playbackRate}
                  onChange={changePlaybackRate}
                  min={0.25}
                  max={3}
                  step={0.25}
                />
              </Grid>
              <Grid item className={classes.playbackRateLabel}>
                <Typography variant="caption">x {playbackRate}</Typography>
              </Grid>
            </Grid>
          }
        />

        <VerticalDivider />

        <SurferMenuStatItem label="Tiempo" value={formatSecondsFromVideo(currentTime)} />
        <VerticalDivider />

        <SurferMenuStatItem label="Duración" value={formatSecondsFromVideo(duration)} />
        <VerticalDivider />
        <Tooltip title={recording ? 'Pausar tiempo' : 'Grabar tiempo'} arrow>
          <Box
            className={recording ? `${classes.record} ${classes.recording}` : classes.record}
            onClick={handleClickRecord}
            onMouseOver={mouseOverRecording}
            onMouseOut={mouseOutRecording}
          >
            {recording ? (
              <IconButton className={classes.recordButton}>{hoverRecording ? <PauseIcon /> : <FiberManualRecordIcon />}</IconButton>
            ) : (
              <IconButton>
                <FiberManualRecordIcon />
              </IconButton>
            )}
            <Box className={classes.dedicatedTime}>
              {recording ? (
                <Box> GRABANDO ..... </Box>
              ) : (
                <Box>
                  <Box>Tiempo dedicado</Box>
                  <Box>{getDedicationTime}</Box>
                </Box>
              )}
            </Box>
          </Box>
        </Tooltip>
      </Box>
      <Box className={classes.settings} />
    </Box>
  );
};

export default SurferMenu;
