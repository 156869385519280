import { Document, Provider } from '@newtral/editor-svc-client/esm';
import React, { useEffect, useState, RefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, RouteComponentProps, StaticContext } from "react-router";
import { isNull } from "util";
import BackendApi from "../helpers/api/backendApi";
import { setDocument } from "../store/actions/documentActions";
import Loading from "./Loading";
import { selectDocumentId } from "../store/selectors/documentSelectors";
import Alerts, { AlertsRefObject } from "./Alert";
import { Color } from "@material-ui/lab/Alert";
import { VideomaAllMetadata } from '../store/types/documentTypes';


interface RouteParams {
  id?: string;
  provider?: Provider;
  providerId?: string;
  name?: string;
}


interface AdditionalProps {
  alertRef: RefObject<AlertsRefObject>;
}
interface LocationState {
  name?: string;
}
const LoadingDocument = ({
  match,
  location
}: RouteComponentProps<RouteParams, StaticContext, LocationState> & AdditionalProps) => {
  const { id, provider, providerId } = match.params;
  const name = location.state?.name;
  const documentId = useSelector(selectDocumentId);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [textAlert, setTextAlert] = useState<string>("Error");
  const [severity, setSeverity] = useState<Color>("error");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response: Document;
        if (id) {
          response = await BackendApi.getDocumentById<VideomaAllMetadata>(id) ;
        } else if (provider && providerId) {
          const currentProvider = provider.toLocaleUpperCase();
          response = await BackendApi.getDocumentByProvider<VideomaAllMetadata>(currentProvider === Provider.Videoma ? Provider.Videoma : Provider.Medio, providerId) ;
        } else {
          response = await BackendApi.createNamedDocument<VideomaAllMetadata>(name);
        }
        dispatch(setDocument(response));
      } catch(err){
        alertError(err.response.status, err.response.data.message);
      }
    };
    fetchData();
  }, [dispatch, id, name, provider, providerId]);

  const alertError = (status: number, message: string) => {
    setTextAlert(message);
    if(status < 500){
      setSeverity("warning");
    }
    setError(true);
  }

  return (error ?
    ( <div>
        <Alerts text={textAlert} color={severity} show={error ? true: false}/>
        <Loading display={true} />
      </div>
    ) :
    ( isNull(documentId) ? (
      <Loading display={true} />
     ) : (
      <Redirect to={{ pathname: `/${documentId}${location.hash}` }} />
    ))
  );
};

export default LoadingDocument;
