import { SET_PLAYBACK_RATE, SET_TIME, SET_DURATION, SET_PAUSED } from "../types/surferTypes";

export const setPlaybackRate = (rate: number) => {
  return {
    type: SET_PLAYBACK_RATE,
    rate
  };
};
export const setTime = (time: number) => {
  return {
    type: SET_TIME,
    time
  };
};
export const setDuration = (duration: number) => {
  return {
    type: SET_DURATION,
    duration
  };
};
export const setPaused = (paused: boolean) => {
  return {
    type: SET_PAUSED,
    paused
  };
};
