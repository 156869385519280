import React, { KeyboardEvent, useRef, SyntheticEvent, MouseEvent, useCallback, memo, useMemo } from 'react';
import { TimeLineItem } from '../store/types/documentTypes';
import { useSelector, useDispatch } from 'react-redux';
import { selectDocumentEditorTimeLine } from '../store/selectors/documentSelectors';
import { setDocumentPartial } from '../store/actions/documentActions';
import { EditorTimelineProperties, TimelineWords } from '@newtral/editor-svc-client/esm';

interface SentenceProps {
    text: TimelineWords;
    speaker: string;
    hash: string;
    sKey: number;
    playSentence: (start:number, end:number) => void;
    renderChanges: (paragraphs:TimeLineItem, save:boolean) => void;
}

const Sentence = ({text, speaker, hash, sKey, playSentence, renderChanges}: SentenceProps) => {
    
    let prevTime: number = null;
    const order = Object.keys(text).sort((a:string, b:string) => Number(a) - Number(b));
    const time: number = Number([...order].shift());
    const sentenceRef = useRef(null);
    const selectEditorTimeLine:TimeLineItem = useSelector(selectDocumentEditorTimeLine);
    const dispatch = useDispatch();

    const edit = (event: MouseEvent<Element, globalThis.MouseEvent>) =>{
        const last: number = Number(Object.keys(text).pop());
        const docSel = document.getSelection();
        const word = event.target as HTMLSpanElement;
        if(docSel.anchorNode != null && event.pageX > 0 ){
            const wordTime: number = Number(word.getAttribute("data-time"));
            playSentence(wordTime,last);
        }
        if(sentenceRef.current.isContentEditable) {
            sentenceRef.current.contentEditable="false";
            word.focus();
        }
    };

    const moveToWord = (event: KeyboardEvent<HTMLElement>) => {
        const selection = window.getSelection();
        const length = selection.anchorNode.textContent.length;
        const position = selection.getRangeAt(0).startOffset;
        const word = event.target as HTMLSpanElement;
        if(length === position) {
            let nextWord =  word.nextSibling as HTMLSpanElement;
            if(nextWord == null) {
                const nextSentence = word.parentElement?.nextSibling as HTMLElement;
                nextWord = nextSentence?.firstElementChild as HTMLElement;;
            }
            if(nextWord != null && nextWord.getAttribute("data-type") === "word") {
                nextWord.focus();
                nextWord.click();
            }
        }
        if(position < 1) {
            let previousWord = word.previousSibling as HTMLElement;
            if(previousWord == null) {
                const previousSentence = word.parentElement?.previousSibling as HTMLElement;
                previousWord = previousSentence?.lastElementChild as HTMLElement;;
            }
            if(previousWord != null && previousWord.getAttribute("data-type") === "word") {
                const prevLength = previousWord.textContent.length;
                setCursorPosition(prevLength, previousWord);
                previousWord.click();
            }
        }
    };

    const setCursorPosition = (pos:number, previousWord:HTMLElement) => {
        try{
            const range = document.createRange();  
            range.setStart(previousWord.childNodes[0], pos);
            const sel = window.getSelection();
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            previousWord.focus();
        }catch(err){}
    };
    
    const changeText = useCallback((event:SyntheticEvent) => {
        let changed: boolean = false;
        let currentTime = Number(event.currentTarget.getAttribute("data-time"));
        selectEditorTimeLine.forEach((paragraph:EditorTimelineProperties) => {
            if(paragraph[sKey]?.words[currentTime]) {
                if(paragraph[sKey].words[currentTime] !== event.currentTarget.textContent){
                    changed = true;
                    paragraph[sKey].words[currentTime] = event.currentTarget.textContent;
                }
            }
        });
        if(changed){
            dispatch(setDocumentPartial({ editorData: { timeline: selectEditorTimeLine } }));
            renderChanges(selectEditorTimeLine, true);
        }
    }, [dispatch, selectEditorTimeLine, sKey, renderChanges]);

    const changeWord = (event:SyntheticEvent)=>{
        const sentence = sentenceRef.current as HTMLSpanElement;
        if(!sentence.isContentEditable){
            sentence.contentEditable = "true";
        }
        if(event.currentTarget.textContent !== event.currentTarget.getAttribute("data-value")) {
            changeText(event);  
        }
    };

    const getWords = (order:string[]) => {
        return order.map((key:string) => {
            let hasWord:string = "";
            const timeWord = Number(key);
            const value = text[timeWord];
            if(prevTime !== timeWord) {
                prevTime = timeWord;
            } else {
                hasWord = `_${prevTime+1}`;
            }
            return <span
                key={`word_${timeWord}_${timeWord}${hasWord}`} 
                className="word" 
                data-type="word" 
                data-time={timeWord}
                data-value={value}
                data-speaker={speaker} 
                suppressContentEditableWarning={true} 
                contentEditable
                onBlur={changeWord}
                >
                    {value}
                </span>
        });
    };

    const RenderWords = (order:string[]) => {
        return useMemo(() => getWords(order), [order]);
    };  

    return (
        <span 
            ref={sentenceRef} 
            className="sentence" 
            data-type="sentence" 
            data-time={time} 
            data-speaker={speaker} 
            data-hash={hash}
            suppressContentEditableWarning={true} 
            contentEditable={true} 
            onClick={edit}  
            onKeyDown={moveToWord}
        >
            {RenderWords(order)}
        </span>
    );
};

export default memo(Sentence);