// State interfaces

export interface SurferState {
  playbackRate: number;
  time: number;
  duration: number;
  paused: boolean;
}

// Action name constants

export const SET_PLAYBACK_RATE = "SET_PLAYBACK_RATE";
export const SET_TIME = "SET_TIME";
export const SET_DURATION = "SET_DURATION";
export const SET_PAUSED = "SET_PAUSED";

// Action interfaces

export interface SetPlaybackRateAction {
  type: typeof SET_PLAYBACK_RATE;
  rate: number;
}
export interface SetTimeAction {
  type: typeof SET_TIME;
  time: number;
}
export interface SetDurationAction {
  type: typeof SET_DURATION;
  duration: number;
}
export interface SetPausedAction {
  type: typeof SET_PAUSED;
  paused: boolean;
}

export type SurferActionTypes =
  | SetPlaybackRateAction
  | SetTimeAction
  | SetDurationAction
  | SetPausedAction;
