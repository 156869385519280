import { MarkedItemInfo } from '@newtral/editor-svc-client/esm';
import { User } from '@newtral/editor-svc-client/esm/types/users';
import {
    SET_HIGHLIGHT_STATUS,
    SET_CLAIMBOT_ALGORITHM,
    ClaimbotAlgorithm,
    SET_HISTORY,
    StoreHistory,
    SET_CARD,
    SET_MARK,
    SET_CARD_EDITING,
    SET_DIALOG_ACTION,
    SET_DIALOG_URL,
    SET_USER
} from "../types/editorTypes";


export const setHighlightStatus = (highlight:boolean) => {
    return {
        type: SET_HIGHLIGHT_STATUS,
        highlight
    }
}

export const setClaimbotAlgorithm = (algorithm: ClaimbotAlgorithm) => {
    return {
        type: SET_CLAIMBOT_ALGORITHM,
        claimbotAlgorithm: algorithm
    }
}

export const setHistory = (history: StoreHistory) => {
    return {
      type: SET_HISTORY,
      history
    };
};

export const setCurrentCard = (card: string) => {
    return {
      type: SET_CARD,
      card
    };
};

export const setCurrentMark = (mark: MarkedItemInfo | null) => {
    return {
      type: SET_MARK,
      mark
    };
};

export const setCurrentCardEditing = (editing: string) => {
    return {
      type: SET_CARD_EDITING,
      editing
    };
};

export const setDialogAction = (action: string) => {
    return {
      type: SET_DIALOG_ACTION,
      action
    };
};

export const setDialogURL = (url: string) => {
  return {
    type: SET_DIALOG_URL,
    url
  };
};

export const setUser = (user: User | {}) => {
  return {
    type: SET_USER,
    user
  };
};