import {
  SET_DOCUMENT,
  SET_DOCUMENT_PARTIAL,
  SET_DOCUMENT_HASH
} from "../types/documentTypes";
import { RecursivePartial } from "../../interfaces/recursivePartial";
import { Document } from '@newtral/editor-svc-client/esm';

export const setDocument = (document: Document) => {
  return {
    type: SET_DOCUMENT,
    document
  };
};

export const setDocumentPartial = (
  partialDocument: RecursivePartial<Document>
) => {
  return {
    type: SET_DOCUMENT_PARTIAL,
    partialDocument
  };
};

export const setDocumentHash = (hash: string) => {
  return {
    type: SET_DOCUMENT_HASH,
    hash
  };
};

