import { Document, EditorData, MarkedItemInfo, TimelineSpeaker } from '@newtral/editor-svc-client/esm';
import { RecursivePartial } from '../../interfaces/recursivePartial';

// Marks highlight types

export enum HightLightTypes {
  normal = 'normal',
  pinned = 'pinned'
}

export enum ConfidenceEnum {
  low = 'low',
  medium = 'medium',
  high = 'high'
}

// State interfaces
export interface TranscriptionItem {
  value: string;
  readonly time: number;
  speaker?: string;
}

export type WordItemTranscription = TranscriptionItem[];

export interface InterventionItem {
  readonly speaker: string;
  readonly time: number;
  readonly words: WordItemTranscription;
}
export interface WordsItem {
  [key: number]: string;
}

export type SentencesItem = TimelineSpeaker[];

export type TimeLineItem = EditorData['timeline'];

export type SpeakerItem = TranscriptionItem;

export interface MedioMetadata {
  title: string;
  source: string;
  date: string;
  authors: string[];
}

export interface VideomaMetadata {
  TITULO: string;
  FUENTE: string;
  FECHA: string;
  PERSONAJE: string[];
  DESCRIPTORES: string[];
}

export interface VideomaAllMetadata extends VideomaMetadata {
  'NOMBRE PRODUCCION': string;
  IDIOMA: string[];
  DERECHOS: string;
  PARTIDO: string[];
  'CARGO ORGANICO'?: any;
  'CARGO PUBLICO'?: any;
  'EN CALIDAD DE'?: any;
  'LUGAR CARGO POLITICO'?: any;
  'LUGAR DECLARACIONES'?: any;
  'PERIODO ELECTORAL': string;
  'CARGO EN CAMPAÑA'?: any;
  RESPONSABLE: string;
  'VISIONADO POR'?: any;
  'FECHA VISIONADO'?: any;
  'TRANSCRIPCION CORREGIDA': string;
  titulo: string;
  duracion: string;
  url: string;
}

export interface DocumentState {
  document: Document;
  hash: string;
}

// Action name constants

export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_DOCUMENT_PARTIAL = 'SET_DOCUMENT_PARTIAL';
export const SET_DOCUMENT_HASH = 'SET_DOCUMENT_HASH';

// Action interfaces

export interface SetDocumentAction {
  type: typeof SET_DOCUMENT;
  document: Document;
}

export interface SetDocumentPartialAction {
  type: typeof SET_DOCUMENT_PARTIAL;
  partialDocument: RecursivePartial<Document>;
}

export interface SetDocumentHash {
  type: typeof SET_DOCUMENT_HASH;
  hash: string;
}

export type DocumentActionTypes = SetDocumentAction | SetDocumentPartialAction | SetDocumentHash;

// Other

export const markedItemInfoComparatorByInitial = (a: MarkedItemInfo, b: MarkedItemInfo) => parseFloat(a.initial) - parseFloat(b.initial);
