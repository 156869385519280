import { Box, Paper, Theme, Typography, IconButton, Tooltip } from '@material-ui/core';
import { MarkedItemInfo } from '@newtral/editor-svc-client/esm';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, forwardRef, useState, SyntheticEvent, useEffect } from 'react';
import { ConfidenceEnum, HightLightTypes } from '../store/types/documentTypes';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import { blue, grey } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import { selectDocumentEditorDataMarked } from '../store/selectors/documentSelectors';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';
import { selectEditorCurrentCardEditing } from '../store/selectors/editorSelectors';
import DomHelper from '../helpers/domHelper';

const cardHoverEffect = {
  outlineWidth: 1,
  outlineStyle: 'dashed',
  outlineColor: grey[400]
};

const cardPinnedHoverEffect = {
  outlineWidth: 1,
  outlineStyle: 'dashed',
  outlineColor: blue[600]
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
    margin: theme.spacing(1.5)
  },
  editableContainer: {
    '&:hover': cardHoverEffect,
    padding: theme.spacing(0.5)
  },
  currentEditableContainer: cardHoverEffect,
  editablePinnedContainer: {
    '&:hover': cardPinnedHoverEffect,
    padding: theme.spacing(0.5)
  },
  currentPinnedEditableContainer: cardPinnedHoverEffect,
  editable: {
    outline: 'none'
  },
  actions: {
    justifyContent: 'space-between',
    display: 'flex',
    height: '35px'
  },
  pin: {
    padding: '5px'
  },
  pinned: {
    border: `1px solid ${blue[800]}`,
    backgroundColor: blue[800],
    color: 'white',
    '& $pinnedIcon': {
      color: 'white !important'
    },
    '& $edit': {
      color: 'white !important'
    },
    '& $delete': {
      color: 'white !important'
    },
    '& $confidence': {
      color: 'white !important'
    }
  },
  low: {
    border: `1px solid ${blue[100]}`
  },
  medium: {
    border: `1px solid ${blue[400]}`
  },
  high: {
    border: `1px solid ${blue['A400']}`
  },
  pinnedIcon: {
    color: blue[800]
  },
  unPinnedIcon: {
    color: blue[100]
  },
  unPinnedIconLow: {
    color: blue[50]
  },
  unPinnedIconMedium: {
    color: blue[100]
  },
  unPinnedIconHigh: {
    color: '#aacaff'
  },
  clicked: {
    border: `1px solid ${blue[900]}`
  },
  confidence: {
    color: 'black'
  },
  edit: {
    padding: '5px',
    color: '#1565c0',
    '&:hover, &:active': {
      backgroundColor: '#3f69b538 !important'
    }
  },
  delete: {
    padding: '5px',
    color: '#1565c0',
    marginLeft: 'auto',
    '&:hover, &:focus, &:active': {
      backgroundColor: '#3f69b538  !important'
    }
  },
  editing: {
    padding: '5px',
    color: '#FFFFFF',
    backgroundColor: '#1565c0  !important'
  }
}));

interface ClaimCardProps {
  mark: MarkedItemInfo;
  updateMark: Function;
  deleteMark: Function;
  showMarkText: Function;
  formatMarked: Function;
  clickCard: Function;
  clickEdit: Function;
  clicked: Boolean;
}

const ClaimCard = forwardRef<HTMLDivElement, ClaimCardProps>(
  ({ mark, updateMark, deleteMark, showMarkText, formatMarked, clickCard, clickEdit, clicked }, ref) => {
    const classes = useStyles({});
    const [pinned, setPinned] = useState(mark.highlight !== HightLightTypes.pinned ? false : true);
    const selectDocumentEditorDataMarks = useSelector(selectDocumentEditorDataMarked);
    const currentCardEditing = useSelector(selectEditorCurrentCardEditing);
    const isCurrentCardEditing = currentCardEditing === mark.id;

    const confidence = DomHelper.getConfidence(mark?.confidence);

    const handleClickCard = useCallback(() => {
      if (currentCardEditing === null) {
        showMarkText(mark);
        clickCard(mark);
      }
    }, [currentCardEditing, mark, showMarkText, clickCard]);

    const handleClickDelete = useCallback(
      (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        deleteMark(mark);
      },
      [deleteMark, mark]
    );

    const handleClickEdit = useCallback(
      (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (isCurrentCardEditing) {
          return clickEdit(null);
        }
        clickEdit(mark.id);
        showMarkText(mark);
        event.preventDefault();
        event.stopPropagation();
      },
      [clickEdit, isCurrentCardEditing, mark, showMarkText]
    );

    const handleHoverEdit = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      event.currentTarget.focus();
    };

    const handleChangeCard = (event: SyntheticEvent) => {
      const card = event.target as HTMLElement;
      updateMark({ ...mark, text: card.textContent });
    };

    const currentEditableClass = () => {
      if (pinned) {
        return `${classes.editablePinnedContainer} ${classes.currentPinnedEditableContainer}`;
      }
      return `${classes.editableContainer} ${classes.currentEditableContainer}`;
    };

    const editableContainerClass = ref ? currentEditableClass() : classes.editableContainer;

    const currentClass = () => {
      if (pinned) {
        return `${classes.pinned} ${classes.root}`;
      }
      if (confidence === ConfidenceEnum.low) {
        return `${classes.low} ${classes.root}`;
      }
      if (confidence === ConfidenceEnum.medium) {
        return `${classes.medium} ${classes.root}`;
      }
      if (confidence === ConfidenceEnum.high) {
        return `${classes.high} ${classes.root}`;
      }
      return `${classes.root}`;
    };

    const confidenceIcon = () => {
      if (confidence === ConfidenceEnum.low) {
        return classes.unPinnedIconLow;
      }
      if (confidence === ConfidenceEnum.medium) {
        return classes.unPinnedIconMedium;
      }
      if (confidence === ConfidenceEnum.high) {
        return classes.unPinnedIconHigh;
      }
      return classes.unPinnedIcon;
    };

    const getPercentageConfidence = (confidence: number) => {
      if (confidence) {
        return `${DomHelper.getPercentageConfidence(confidence)}%`;
      }
      return '';
    };

    const cardClass = clicked ? `${classes.root} ${classes.clicked}` : currentClass();

    const handleClickPinned = useCallback(
      (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        const classMark = !pinned ? HightLightTypes.pinned : HightLightTypes.normal;
        selectDocumentEditorDataMarks[mark.sentence][`${mark.sentence}-${mark.initial}`].highlight = classMark;
        formatMarked(selectDocumentEditorDataMarks);
        updateMark({ ...mark, highlight: classMark });
        setPinned(!pinned);
      },
      [formatMarked, mark, pinned, selectDocumentEditorDataMarks, updateMark]
    );

    useEffect(() => {
      setPinned(false);
      if (mark.highlight === HightLightTypes.pinned) {
        setPinned(true);
      }
    }, [mark]);

    return (
      <Paper ref={ref} onClick={handleClickCard} className={cardClass} data-id={mark.id}>
        <Typography variant="body2" component="div" className={editableContainerClass}>
          <div
            id={mark.id}
            className={classes.editable}
            contentEditable={isCurrentCardEditing}
            suppressContentEditableWarning={true}
            onBlur={handleChangeCard}
          >
            {mark.text}
          </div>
        </Typography>
        <Box textAlign="left" mt={1} className={classes.actions}>
          <IconButton className={classes.pin} onClick={handleClickPinned}>
            {pinned ? (
              <Tooltip title="Desmarcar" arrow>
                <TurnedInIcon className={classes.pinnedIcon} />
              </Tooltip>
            ) : (
              <Tooltip title="Marcar" arrow>
                <TurnedInNotIcon className={confidenceIcon()} />
              </Tooltip>
            )}
          </IconButton>
          <IconButton
            className={isCurrentCardEditing ? classes.editing : classes.edit}
            onClick={handleClickEdit}
            onMouseOver={handleHoverEdit}
          >
            <Tooltip title="Editar" arrow>
              <CreateIcon />
            </Tooltip>
          </IconButton>
          <IconButton className={classes.confidence}>
            <Tooltip title="Confianza" arrow>
              <Typography>{pinned ? '100%' : getPercentageConfidence(mark?.confidence)}</Typography>
            </Tooltip>
          </IconButton>
          <IconButton className={classes.delete} onClick={handleClickDelete}>
            <Tooltip title="Eliminar" arrow>
              <DeleteOutlineIcon />
            </Tooltip>
          </IconButton>
        </Box>
      </Paper>
    );
  }
);

export default ClaimCard;
