import { Box, Grid, IconButton, Link, Theme, Tooltip, Chip } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { MarkedItemInfo } from '@newtral/editor-svc-client/esm';
import { ReactComponent as ClaimbotIcon } from '../styles/icons/claimbot.svg';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentPartial } from '../store/actions/documentActions';
import { selectDocumentEditorDataMarked, selectDocumentEditorBotMode } from '../store/selectors/documentSelectors';
import { selectEditorCurrentCard } from '../store/selectors/editorSelectors';
import { setCurrentCard, setCurrentCardEditing, setCurrentMark, setDialogAction } from '../store/actions/editorActions';
import { HightLightTypes, markedItemInfoComparatorByInitial } from '../store/types/documentTypes';
import { MENU_HEIGHT, SURFER_HEIGHT, TOOLBAR_TO_EDITOR_MARGIN } from '../styles/constants';
import ClaimCard from './ClaimCard';
import DomHelper from '../helpers/domHelper';
import Editor from './Editor';
import SpotlightHelper from '../helpers/spotlightHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '27vw',
    left: '73vw',
    top: MENU_HEIGHT + TOOLBAR_TO_EDITOR_MARGIN,
    color: '#1565c0',
    marginBottom: 10,
    height: `calc(100% - ${MENU_HEIGHT + TOOLBAR_TO_EDITOR_MARGIN + 10}px)`
  },
  cardContainer: {
    overflow: 'auto',
    height: 'calc(100% - 22px)'
  },
  play: {
    padding: 0,
    color: '#1565c0'
  },
  head: {
    borderBottom: '1px solid #e7e7e7',
    padding: '5px'
  },
  sentencesTitle: {
    marginTop: 2
  },
  link: {
    color: '#1565c0'
  },
  bot: {
    stroke: '#1565c0',
    fill: '#1565c0',
    color: '#1565c0',
    marginLeft: '6px !important',
    marginBottom: '2px'
  }
}));

interface RightPanelProps {
  isAudio: boolean;
  editorRef: React.RefObject<typeof Editor.WrappedComponent.prototype>;
  setUnsavedChanges: Function;
  markedToScrollTo: Partial<MarkedItemInfo>;
  setMarkedToScrollTo: (markedToScroll: Partial<MarkedItemInfo>) => void;
  markedKaraokeEnabled: boolean;
  setMarkedKaraokeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightPanel = ({
  isAudio,
  editorRef,
  setUnsavedChanges,
  markedToScrollTo,
  setMarkedToScrollTo,
  markedKaraokeEnabled,
  setMarkedKaraokeEnabled
}: RightPanelProps) => {
  const classes = useStyles({});
  const claimToScrollToRef = useRef(null);
  const marks = useSelector(selectDocumentEditorDataMarked);
  const currentCard = useSelector(selectEditorCurrentCard);
  const botMode = useSelector(selectDocumentEditorBotMode);
  const [bot, setBot] = useState(botMode);
  const dispatch = useDispatch();

  const claimCards: MarkedItemInfo[] = Object.values(marks)
    .reduce((prev, current) => prev.concat(Object.values(current)), [])
    .sort(markedItemInfoComparatorByInitial);

  const countPinnedCards = claimCards.filter(card => card.highlight === HightLightTypes.pinned).length;

  const countNormalCards = claimCards.length - countPinnedCards;

  let claimToScrollTo: MarkedItemInfo = null;
  if (markedToScrollTo) {
    claimToScrollTo = claimCards.find(
      e => e.sentence === (markedToScrollTo as MarkedItemInfo).sentence && e.initial === (markedToScrollTo as MarkedItemInfo).initial
    );
  }

  const modeBot = () => {
    setBot(!bot);
    dispatch(
      setDocumentPartial({
        editorData: { botmode: !bot }
      })
    );
    setUnsavedChanges(true);
  };

  const updateMark = useCallback(
    async (mark: MarkedItemInfo) => {
      const subMark = marks[mark.sentence];
      const timeKey = `${mark.sentence}-${mark.initial}`;
      const newSubMark = { ...subMark, [timeKey]: mark };
      await dispatch(
        setDocumentPartial({
          editorData: { marks: { ...marks, [mark.sentence]: newSubMark } }
        })
      );
      editorRef.current.addToHistory();
      setUnsavedChanges(true);
    },
    [editorRef, dispatch, marks, setUnsavedChanges]
  );

  const deleteMark = useCallback(
    (mark: MarkedItemInfo) => {
      const newMarked = { ...marks };
      const subMark = newMarked[mark.sentence];
      const timeKey = `${mark.sentence}-${mark.initial}`;
      delete subMark[timeKey];
      if (Object.keys(subMark).length === 0) {
        delete newMarked[mark.sentence];
      }
      dispatch(
        setDocumentPartial({
          editorData: { marks: newMarked }
        })
      );
      DomHelper.deleteMark(mark.id);
      DomHelper.setSentenceContentEditable(Number(mark.sentence));
      editorRef.current.addToHistory();
      setUnsavedChanges(true);
      dispatch(setCurrentMark(null));
      dispatch(setDialogAction(null));
      dispatch(setCurrentCardEditing(null));
    },
    [dispatch, editorRef, marks, setUnsavedChanges]
  );

  const dialogDeleteMark = useCallback(
    (mark: MarkedItemInfo) => {
      dispatch(setCurrentMark(mark));
      deleteMark(mark);
    },
    [deleteMark, dispatch]
  );

  const showMarkText = useCallback(
    (mark: MarkedItemInfo) => {
      // Workaround to avoid firing scroll 2 times: editor text and right panel
      setTimeout(setMarkedToScrollTo, 1000, mark);
      const marks = DomHelper.getMarks(mark.id);
      SpotlightHelper.spotlightMarks(marks);
    },
    [setMarkedToScrollTo]
  );

  const formatMarked = useCallback(
    marks => {
      return editorRef.current.formatMarked(marks);
    },
    [editorRef]
  );

  const handleClickCard = useCallback(
    (mark: MarkedItemInfo) => {
      editorRef.current.playSentence(Number(mark.initial), Number(mark.final));
      dispatch(setCurrentCard(mark.id));
    },
    [dispatch, editorRef]
  );

  const handleClickEdit = useCallback(
    (markId: string) => {
      editorRef.current.pause();
      dispatch(setCurrentCard(markId));
      dispatch(setCurrentCardEditing(markId));
    },
    [dispatch, editorRef]
  );

  const handleClickKaraokePlus = useCallback(() => {
    setMarkedKaraokeEnabled(prevValue => {
      if (prevValue) {
        editorRef.current.markedKaraokeStop();
      } else {
        editorRef.current.markedKaraokeStart();
      }
      return !prevValue;
    });
  }, [editorRef, setMarkedKaraokeEnabled]);

  useEffect(() => {
    if (claimToScrollToRef.current) {
      setTimeout(DomHelper.scrollToElement, 0, claimToScrollToRef.current);
    }
  }, [claimToScrollTo, marks, bot]);

  return (
    <Box
      className={classes.root}
      style={
        isAudio
          ? {
              height: `calc(100% - ${MENU_HEIGHT + TOOLBAR_TO_EDITOR_MARGIN + SURFER_HEIGHT + 10}px)`
            }
          : {}
      }
    >
      <Grid container className={classes.head}>
        <Grid item className={classes.sentencesTitle}>
          <Link className={classes.link} display="block" component="a" variant="inherit" underline="none">
            <span>
              Frases marcadas (
              <Tooltip title="Total" arrow>
                <span>{claimCards.length}</span>
              </Tooltip>
              )
            </span>
            {countNormalCards > 0 && (
              <Tooltip title="Normales" arrow>
                <span className="mark-normal count-marks">{countNormalCards} </span>
              </Tooltip>
            )}
            {countPinnedCards > 0 && (
              <Tooltip title="Marcadas" arrow>
                <span className="mark-pinned count-marks"> {countPinnedCards}</span>
              </Tooltip>
            )}
          </Link>
        </Grid>
        <Grid item style={isAudio ? {} : { display: 'none' }}>
          <IconButton className={classes.play} onClick={handleClickKaraokePlus} disabled={claimCards.length === 0}>
            {markedKaraokeEnabled ? (
              <Tooltip title="Pausar" arrow>
                <PauseIcon height={24} />
              </Tooltip>
            ) : (
              <Tooltip title="Reproducir" arrow>
                <PlayIcon height={24} />
              </Tooltip>
            )}
          </IconButton>
        </Grid>
        <Grid item>
          <Tooltip title="Modo Bot" arrow>
            <Chip
              className={classes.bot}
              variant="outlined"
              color="default"
              size="small"
              label={bot ? 'On' : 'Off'}
              onClick={modeBot}
              icon={<ClaimbotIcon className={classes.bot} />}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Box className={classes.cardContainer}>
        {claimCards.map((e: MarkedItemInfo) => (
          <ClaimCard
            key={`${e.initial}-${e.final}`}
            mark={e}
            updateMark={updateMark}
            deleteMark={dialogDeleteMark}
            showMarkText={showMarkText}
            formatMarked={formatMarked}
            clickCard={handleClickCard}
            clickEdit={handleClickEdit}
            clicked={currentCard === e.id ? true : false}
            ref={_.isEqual(claimToScrollTo, e) || currentCard === e.id ? claimToScrollToRef : null}
          />
        ))}
      </Box>
    </Box>
  );
};

export default RightPanel;
