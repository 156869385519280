import { IconButton, Box, Theme, Tooltip } from "@material-ui/core";
import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import RewindIcon from "@material-ui/icons/FastRewind";
import ForwardIcon from "@material-ui/icons/FastForward";

const useStyles = makeStyles((theme: Theme) => ({
  controllers: {
    height: "100%",
    backgroundColor: "#e0e0e0"
  }
}));

interface SurferMenuControllersProps {
  isPlaying: boolean;
  videoRewind: Function;
  videoForward: Function;
  playVideo: Function;
  pauseVideo: Function;
}

const SurferMenuControllers = ({
  isPlaying,
  videoRewind,
  videoForward,
  playVideo,
  pauseVideo
}: SurferMenuControllersProps) => {
  const classes = useStyles({});

  const handleClickRewind = useCallback(() => {
    videoRewind();
  }, [videoRewind]);

  const handleClickForward = useCallback(() => {
    videoForward();
  }, [videoForward]);

  const handleClickPlay = useCallback(() => {
    playVideo();
  }, [playVideo]);

  const handleClickPause = useCallback(() => {
    pauseVideo();
  }, [pauseVideo]);
  return (
    <Box className={classes.controllers} display="flex" alignItems="center">
      <Tooltip title="Retroceder" arrow>
        <IconButton onClick={handleClickRewind}>
          <RewindIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Pausar" arrow>
        <IconButton
          onClick={handleClickPause}
          style={{ display: isPlaying ? "initial" : "none" }}
        >
          <PauseIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Reproducir" arrow>
        <IconButton
          onClick={handleClickPlay}
          style={{ display: isPlaying ? "none" : "initial" }}
        >
          <PlayIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Avanzar" arrow>
        <IconButton onClick={handleClickForward}>
          <ForwardIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default SurferMenuControllers;
