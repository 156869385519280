import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert, { Color } from '@material-ui/lab/Alert';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2)
            },
            position: 'absolute',
            zIndex: 9999
        },
    }),
);

interface AlertsProps {
    show: boolean;
    text?: string;
    color?: Color;
}

export interface AlertsRefObject {
    showAlert: (text: string, color: Color) => void;
}


const Alerts = ({show, text, color}: AlertsProps, ref: Ref<AlertsRefObject>) => {
    
    const classes = useStyles({});

    useImperativeHandle(ref, () => ({
        showAlert
    }));

    const [open, setOpen] = useState(show);
    const [textAlert, setTextAlert] = useState<string>(text);
    const [severity, setSeverity] = useState<Color>(color);

    const showAlert = (text: string, color: Color) => {
        setTextAlert(text);
        setSeverity(color);
        setOpen(true);
        setTimeout(()=>{
            setOpen(false);
        },10000);
    }

    return (
        <div className={classes.alert}>
            <Collapse in={open}>
                <Alert 
                    variant="filled" 
                    severity={severity} 
                    action={
                        <IconButton 
                            aria-label="close" 
                            color="inherit" 
                            size="small" 
                            onClick={() => { setOpen(false); }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {textAlert}
                </Alert>
            </Collapse>
        </div>
    );
}

export default forwardRef<AlertsRefObject, AlertsProps>(Alerts);