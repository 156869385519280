import axios from "axios";
import { NotificationRequestInterface } from "../../interfaces";

export default class NotificationApi {
  static async sendSentences(payload: NotificationRequestInterface | NotificationRequestInterface[]): Promise<any> {
    try{
      const response =  await axios({
        url: window._env_.REACT_APP_NOTIFICATION,
        method: 'post',
        data: payload
      })
      return response.data;
    } catch(err) {
      return { error:{ status: err.response?.status, message: `NotificationApi: ${err.message}` }};
    }
  };
}
