import { Box, Theme } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 1,
    height: "100%",
    backgroundColor: "#efefef"
  }
}));

const VerticalDivider = () => {
  const classes = useStyles({});

  return <Box className={classes.root} />;
};

export default VerticalDivider;
