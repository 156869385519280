import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { forwardRef, memo, Ref } from "react";
import {
  BigPlayButton,
  ControlBar,
  FullscreenToggle,
  Player,
  VolumeMenuButton
} from "video-react";
import "video-react/dist/video-react.css";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "none",
    position: "absolute",
    bottom: "103px",
    left: "10px",
    width: "25vw",
    boxShadow: "0 2px 10px 0 rgba(0,0,0,.1)",
    pointerEvents: "none"
  },
  controlBar: {
    pointerEvents: "auto"
  },
  "@global": {
    ".video-react-big-play-button": {
      display: "none !important"
    },
    ".video-react-fullscreen-control": {
      position: "absolute !important" as "absolute",
      right: 0
    }
  }
}));

interface VideoProps {
  source: string;
  audio: string;
}

const Video = ({ source, audio }: VideoProps, ref: Ref<typeof Player>) => {
  const classes = useStyles({});

  return (
    <Box className={source ? classes.root : `${classes.root} hide`} id="video">
      <Player ref={ref}>
        <source src={source ? source : audio} />
        <ControlBar
          autoHide={true}
          disableDefaultControls
          className={classes.controlBar}
        >
          <VolumeMenuButton />
          <FullscreenToggle />
        </ControlBar>
        <BigPlayButton position="center" />
      </Player>
    </Box>
  );
};

export default memo(forwardRef(Video));
