import { Box, makeStyles, Theme } from '@material-ui/core';
import { AudioPeaks } from '@newtral/editor-svc-client/esm';
import React, { forwardRef, memo, Ref, RefObject, useEffect, useRef, useImperativeHandle, useState, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { FooterRefObject } from './Footer';
import LoadingWave from './LoadingWave';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 35,
    width: '100%'
  }
}));

let peaks: Array<number> = [];

export interface SurferRefObject {
  waveSurferInstance: RefObject<WaveSurfer>;
}

interface SurferProps {
  isVideo: boolean;
  footerRef: RefObject<FooterRefObject>;
  source: string;
  getPeaksByDocumentId: () => Promise<AudioPeaks>;
  createPeaksByDocumentId: (peaks: AudioPeaks) => Promise<AudioPeaks>;
}

const Surfer = ({ isVideo, footerRef, source, getPeaksByDocumentId, createPeaksByDocumentId }: SurferProps, ref: Ref<SurferRefObject>) => {
  const classes = useStyles({});
  const waveSurferInstance = useRef(null);
  const [loading, setLoading] = useState(true);

  const getPeaks = useCallback(async () => {
    const length = waveSurferInstance.current.getDuration();
    const start = 0;
    const end = length;
    peaks = waveSurferInstance.current.backend.getPeaks(length, start, end);
    if (peaks?.length > 0) {
      document.querySelector('wave').classList.add('fadewave');
      await createPeaksByDocumentId({ audio: peaks });
    }
  }, [createPeaksByDocumentId]);

  const createWave = useCallback(() => {
    if (isVideo) {
      const videoWave: HTMLVideoElement = document.querySelector('#video');
      videoWave.style.display = 'block';
    }
    waveSurferInstance.current = WaveSurfer.create({
      barWidth: 1,
      barHeight: 2.7,
      maxCanvasWidth: 1920,
      container: '#waveform',
      cursorColor: 'navy',
      backend: 'MediaElementWebAudio',
      fillParent: true,
      mediaType: 'audio',
      hideScrollbar: true,
      mediaControls: false,
      pixelRatio: 1,
      height: 35,
      progressColor: '#1565c0',
      responsive: true,
      waveColor: '#a0a7ae'
    });
    waveSurferInstance.current.drawer.containerWidth = '100vw';
    waveSurferInstance.current.setMute(true);
    if (peaks.length < 1) {
      waveSurferInstance.current.load(source);
    } else {
      waveSurferInstance.current.load(source, peaks, 'auto');
    }
  }, [isVideo, source]);

  const seekWave = useCallback(() => {
    waveSurferInstance.current.on('seek', () => {
      try {
        const currentTime = waveSurferInstance.current.getCurrentTime();
        footerRef.current.seek(currentTime);
      } catch (err) {
        console.error(err);
      }
    });
  }, [footerRef]);

  const waveReady = useCallback(() => {
    waveSurferInstance.current.on('ready', () => {
      seekWave();
      if (peaks.length > 0) {
        document.querySelector('wave').classList.add('fadewave');
        setLoading(false);
      }
    });

    waveSurferInstance.current.on(
      'waveform-ready',
      () => {
        if (peaks.length < 1) {
          getPeaks();
        }
        setLoading(false);
      },
      { once: true }
    );
  }, [seekWave, getPeaks]);

  useEffect(() => {
    setLoading(true);
    const peaksByDocumentId = async () => {
      try {
        const audioPeaks = await getPeaksByDocumentId();
        if (audioPeaks?.audio) {
          peaks = audioPeaks.audio;
        }
      } catch (err) {
        if (err.response.status !== 404) {
          console.error(err);
        }
      }
      createWave();
      waveReady();
    };
    peaksByDocumentId();
    return () => {
      waveSurferInstance.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    waveSurferInstance
  }));

  return (
    <Box id="waveform" className={classes.root}>
      <LoadingWave display={loading} />
      <Box className="waveform-line" />
    </Box>
  );
};

export default memo(forwardRef(Surfer));
