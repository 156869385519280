import { createSelector } from "reselect";
import { AppState } from "..";
import { DocumentState } from "../types/documentTypes";
import { Document } from '@newtral/editor-svc-client/esm';

export const selectDocument = createSelector(
  (state: AppState) => state.document.document,
  (document) => document
);

export const selectDocumentHash = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) => documentState.hash
);

export const selectDocumentId = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document && documentState.document._id
);

export const selectDocumentAudioUrl = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document && documentState.document.providerData.audioUrl
);

export const selectDocumentVideoUrl = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document && documentState.document.providerData.videoUrl
);

export const selectDocumentUpdatedAt = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document && documentState.document.updatedAt
);

export const selectDocumentName = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document && documentState.document.name
);

export const selectDocumentStatus = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document && documentState.document.status
);


export const selectDocumentDedicationTime = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document && documentState.document.dedicationTime
);

export const selectDocumentIdEditorAndProviderData = createSelector(
  [selectDocument],
  (document: Document) => {
    return {
      id: document && document._id,
      name: document && document.name,
      editorData: document && document.editorData,
      providerData: document && document.providerData
    };
  }
);

export const selectDocumentEditorDataMarked = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document &&
    documentState.document.editorData &&
    documentState.document.editorData.marks
);

export const selectDocumentEditorTimeLine = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document &&
    documentState.document.editorData &&
    documentState.document.editorData.timeline
);

export const selectDocumentEditorBotMode = createSelector(
  (state: AppState) => state.document,
  (documentState: DocumentState) =>
    documentState.document &&
    documentState.document.editorData &&
    documentState.document.editorData.botmode
);
