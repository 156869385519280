import { Box, Link, Typography, Theme } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { selectDocumentUpdatedAt, selectDocumentIdEditorAndProviderData } from '../store/selectors/documentSelectors';
import { MedioMetadata, VideomaMetadata, VideomaAllMetadata } from '../store/types/documentTypes';
import _ from 'lodash';
import moment from 'moment';
import { MENU_HEIGHT, TOOLBAR_TO_EDITOR_MARGIN } from '../styles/constants';
import Editor from './Editor';

const getLastSavedFormatted = (lastSaved: Date | string) => moment(lastSaved).fromNow();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    height: 300,
    width: '25vw',
    top: MENU_HEIGHT + TOOLBAR_TO_EDITOR_MARGIN,
    left: 20,
    color: '#333333'
  },
  saveButton: {
    '&:disabled': {
      '&:hover': {
        textDecoration: 'none'
      },
      opacity: 0.7,
      cursor: 'default'
    },
    color: '#1565c0'
  },
  saving: {
    color: '#1565c0'
  },
  iconSave: {
    verticalAlign: 'bottom'
  }
}));

interface LeftPanelProps {
  editorRef: React.RefObject<typeof Editor.WrappedComponent.prototype>;
  saving: boolean;
  unsavedChanges: boolean;
}

const LeftPanel = ({ editorRef, saving, unsavedChanges }: LeftPanelProps) => {
  const lastSaved = useSelector(selectDocumentUpdatedAt);
  const provider = useSelector(selectDocumentIdEditorAndProviderData);

  const [timeFromLastSaved, setTimeFromLastSaved] = useState<string>(lastSaved ? getLastSavedFormatted(lastSaved) : 'Nunca');
  const [metadata, setMetadata] = useState(null);

  const classes = useStyles({});

  const tick = useCallback(() => {
    setTimeFromLastSaved(lastSaved ? getLastSavedFormatted(lastSaved) : 'Nunca');
  }, [lastSaved]);

  const handleSaveClick = useCallback(() => {
    return editorRef.current.save();
  }, [editorRef]);

  const meta = useCallback(() => {
    if (provider.providerData?.metadata) {
      let metadataFiltered;
      const videomaMetadataFiltered: VideomaMetadata = _.pick(provider.providerData.metadata as VideomaAllMetadata, [
        'TITULO',
        'FUENTE',
        'FECHA',
        'PERSONAJE',
        'DESCRIPTORES'
      ]);
      metadataFiltered = videomaMetadataFiltered;
      if (Object.keys(metadataFiltered).length < 1) {
        metadataFiltered = _.pick(provider.providerData.metadata, ['title', 'source', 'date', 'authors']) as MedioMetadata;
        metadataFiltered.date = new Date(metadataFiltered.date).toLocaleDateString();
      }
      const data = Object.entries(metadataFiltered).map(([title, value], index) => {
        if (!!value) {
          const data = typeof value === 'string' ? value : value.flat().toString();
          return (
            <Box key={`${index}`}>
              <span className="metadata-title">{title.toLowerCase()}: </span>
              <span className="metadata-value">{data.toUpperCase()}</span>
            </Box>
          );
        }
        return false;
      });
      return data;
    }
  }, [provider]);

  useEffect(() => {
    setMetadata(meta);
    let timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  }, [tick, meta]);

  return (
    <Box className={classes.root}>
      {saving ? (
        <Typography className={classes.saving} display="block" variant="body2">
          <SaveIcon className={classes.iconSave} /> Guardando...
        </Typography>
      ) : (
        <Link
          className={classes.saveButton}
          display="block"
          component="button"
          variant="body2"
          onClick={handleSaveClick}
          disabled={!unsavedChanges}
          underline="none"
        >
          <SaveIcon className={classes.iconSave} /> Guardar
        </Link>
      )}
      <Typography display="block" variant="caption">
        Último guardado
        <Box className="text-capitalize">{timeFromLastSaved}</Box>
      </Typography>
      {provider.providerData?.metadata && (
        <Typography display="block" variant="caption" className="metadata">
          <h3>Metadatos:</h3>
          {metadata}
        </Typography>
      )}
    </Box>
  );
};

export default LeftPanel;
