import { Marked, EditorTimelineProperties, TimelineSpeaker } from '@newtral/editor-svc-client/esm';
import _ from 'lodash';
import {
  WordItemTranscription,
  InterventionItem,
  TranscriptionItem,
  TimeLineItem,
  HightLightTypes,
  ConfidenceEnum
} from '../store/types/documentTypes';
import { v4 as uuid4 } from 'uuid';
import { ClaimDetectionResponseInterface } from '../interfaces';

const textNewParagraph = 'Escribe aquí...';

export default class DomHelper {
  static addSpeakersToWords = (interventions: Array<InterventionItem>) => {
    return interventions.flatMap((intervention: InterventionItem) => {
      intervention.words.forEach((word: TranscriptionItem) => {
        word.speaker = intervention.speaker;
      });
      return intervention;
    });
  };

  static deleteMark = (id: string = null) => {
    let dataMark: string = '[data-mark]';
    if (id !== null) {
      dataMark = `[data-mark="${id}"]`;
    }
    const marks = document.querySelectorAll(dataMark);
    if (marks.length > 0) {
      marks.forEach(mark => {
        mark.removeAttribute('data-mark');
        mark.classList.forEach(item => {
          if (item.startsWith('mark-')) {
            mark.classList.remove(item);
          }
        });
      });
    }
  };

  static editorStart = (editText: HTMLElement) => {
    if (editText.innerHTML.length > 0) {
      editText.classList.remove('outline');
      editText.classList.add('outline-none');
    }
    editText.addEventListener('keydown', ((event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        document.execCommand('insertHTML', false, '<br>');
        event.returnValue = false;
      }
    }) as EventListener);
  };

  static formatMarked = (marks: Marked) => {
    Object.values(marks).forEach(key => {
      Object.values(key).forEach(mark => {
        const sentence = document.querySelector(`[data-time="${parseFloat(mark.sentence)}"][data-type="sentence"]`);
        if (sentence?.children) {
          for (const word of sentence.children) {
            if (
              parseFloat(word.getAttribute('data-time')) >= parseFloat(mark.initial) &&
              parseFloat(word.getAttribute('data-time')) <= parseFloat(mark.final)
            ) {
              word.removeAttribute('class');
              word.setAttribute('data-mark', mark.id);
              let classMark = `mark-${mark.highlight}`;
              if (mark?.confidence) {
                const confidence = DomHelper.getConfidence(mark?.confidence);
                const confidencePercentage = DomHelper.getPercentageConfidence(mark?.confidence);
                classMark = `mark-${confidence}`;
                word.setAttribute('title', `Confianza: ${confidencePercentage}%`);
              }
              if (mark.highlight === HightLightTypes.pinned) {
                classMark = `mark-${mark.highlight}`;
                word.setAttribute('title', `Confianza: 100%`);
              }
              word.classList.add(classMark);
            }
          }
        }
      });
    });
  };

  static getConfidence = (confidence: number) => {
    if (confidence <= 0.5) {
      return ConfidenceEnum.low;
    }
    if (confidence > 0.5 && confidence <= 0.8) {
      return ConfidenceEnum.medium;
    }
    return ConfidenceEnum.high;
  };

  static getPercentageConfidence = (confidence: number) => {
    return (confidence * 100).toFixed(0);
  };

  static getIndexSentences = (sentences: Array<string>) => {
    const indexSentences: Array<number> = [];
    for (let sentence = 0; sentence < sentences.length; sentence++) {
      let currentIndex = sentences[sentence].split(' ').length;
      const dotMatch = sentences[sentence].match(/\./g);
      const dots = dotMatch ? dotMatch.length : 0;
      currentIndex = currentIndex + dots;
      if (sentence > 0) {
        currentIndex = currentIndex + indexSentences[sentence - 1];
      } else {
        currentIndex = currentIndex - 1;
      }
      indexSentences.push(currentIndex);
    }
    return indexSentences;
  };

  static getLastWordChildParagrah = (currentParagraph: HTMLElement) => {
    const lastWordSentence = Array.from(currentParagraph.children)
      .filter(e => e.nodeName === 'SPAN')
      .pop().lastElementChild;
    return parseFloat(lastWordSentence.getAttribute('data-time'));
  };

  static getMarks = (id: string): Element[] => Array.from(document.querySelectorAll(`[data-mark="${id}"]`));

  static getNextOfClass(element: Element, _class: string) {
    let result: Element = null;
    let nextElement = element.nextElementSibling;
    while (nextElement) {
      if (nextElement.classList.contains(_class)) {
        result = nextElement;
        break;
      } else {
        nextElement = nextElement.nextElementSibling;
      }
    }
    return result;
  }

  static getNextSentenceFromSentenceElement = (sentence: Element): Element => {
    let next: Element;
    const currentSentences = Array.from(sentence.parentElement.querySelectorAll('span[data-type="sentence"]'));
    next = currentSentences
      .map((ele, index, arr) => {
        if (ele === sentence) {
          return arr[index + 1];
        }
        return false;
      })
      .filter(el => el)[0] as Element;

    if (!next) {
      const paragraph = DomHelper.getNextOfClass(sentence.parentElement, 'paragraph');
      next = paragraph ? paragraph.firstElementChild : null;
    }
    return next;
  };

  static getPreviousOfClass(element: Element, _class: string) {
    let result: Element = null;
    let prevElement = element.previousElementSibling;
    while (prevElement) {
      if (prevElement.classList.contains(_class)) {
        result = prevElement;
        break;
      } else {
        prevElement = prevElement.previousElementSibling;
      }
    }
    return result;
  }

  static getPreviousSentenceFromSentenceElement = (sentence: Element): Element => {
    let prev = sentence.previousElementSibling;
    if (!prev) {
      const paragraph = DomHelper.getPreviousOfClass(sentence.parentElement, 'paragraph');
      if (paragraph) {
        prev = Array.from(paragraph.children)
          .filter(e => e.nodeName === 'SPAN')
          .pop();
      }
    }
    return prev;
  };

  static getSentenceFromTime = (time: number): Element => {
    const sentences = Array.from(document.querySelectorAll('span[data-type="sentence"]'));
    const lastSentence = sentences.slice().pop();
    if (Number(lastSentence.getAttribute('data-time')) <= time) {
      return lastSentence;
    }
    const sentence = sentences.find((sentence: Element) => parseFloat(sentence.getAttribute('data-time')) >= time);
    const previousSentence = DomHelper.getPreviousSentenceFromSentenceElement(sentence);
    return previousSentence ? previousSentence : sentence;
  };

  static getSentences = (text?: string) => {
    let sentences: string[] = [];
    if (text) {
      const textJoin = text.replace(/(\r\n|\n|\r)/gm, '');
      sentences = textJoin.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|');
    } else {
      const sentencesElements = document.querySelectorAll('[data-type="sentence"]');
      sentences = Array.from(sentencesElements).map(e => e.textContent);
    }
    return sentences;
  };

  static hashSpeaker = () => {
    return Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
  };

  static interventionsFromPaste = (sentences: Array<string>) => {
    const breakParagraph = parseInt(window._env_.REACT_APP_BREAK_PARAGRAPH);
    let time: number = 0;
    let paragraph: WordItemTranscription[] = [];
    return sentences
      .map((sentence, index) => {
        const last = sentence.slice(-1);
        const isBreakChar = DomHelper.isBreakChar(last);
        if (isBreakChar) {
          sentence = `${sentence.slice(0, -1)} ${last}`;
        }
        const splitSentence = sentence.replace('\n', ' ').split(' ');
        const { words, wordsTime } = DomHelper.wordsFromPaste(splitSentence, time);
        let intervention = {};
        paragraph.push(words);
        time = wordsTime;
        if (paragraph.toString().length > breakParagraph || index === sentences.length - 1) {
          intervention = {
            speaker: 'Speaker',
            time: words[0].time,
            words: paragraph.flat()
          };
          paragraph = [];
        }
        return intervention;
      })
      .filter(e => Object.keys(e).length > 0) as InterventionItem[];
  };

  static isBreakChar = (word: string) => {
    return ['.', '?', ';', '!'].includes(word);
  };

  static markFromData = (nlpClaims: ClaimDetectionResponseInterface, marks: Marked) => {
    const sentences = document.querySelectorAll('[data-type="sentence"]');
    Object.entries(nlpClaims.claims_verifiability)
      .filter(([index, element]) => element > 0)
      .forEach(([index]) => {
        const currentSentence = sentences[parseInt(index)] as HTMLElement;
        if (!_.isNil(currentSentence)) {
          const initial = currentSentence.firstElementChild.getAttribute('data-time');
          const finish = currentSentence.lastElementChild.getAttribute('data-time');
          marks = DomHelper.newMark(
            currentSentence,
            parseFloat(initial),
            parseFloat(finish),
            nlpClaims.confidences[parseInt(index)],
            marks
          );
        }
      });
    return marks;
  };

  static mergeNewParagraph = (editorTimeLine: TimeLineItem, speaker: string, time: number) => {
    const mergedTimeLine = editorTimeLine.slice();
    let prev: number = null;
    let added: boolean = false;
    let selectIndex: number = 0;
    for (const [index, element] of editorTimeLine.entries()) {
      const order = Object.keys(element).sort((a: string, b: string) => parseFloat(a) - parseFloat(b));
      let prevElements: EditorTimelineProperties = {};
      let postElements: EditorTimelineProperties = {};
      let newParagraph: EditorTimelineProperties = {};
      let last: boolean = false;
      let keyTime: boolean = false;
      for (const [i, key] of order.entries()) {
        last = index === editorTimeLine.length - 1 && i === Object.keys(element).length - 1;
        keyTime = parseFloat(key) > time;
        //check position
        if (keyTime || last) {
          if (!added) {
            // previous elements
            if (i > 0) {
              order
                .slice()
                .splice(0, i)
                .forEach(e => {
                  prevElements[Number(e)] = element[Number(e)];
                });
            }
            // next elements
            postElements[Number(order[i])] = element[Number(order[i])];
            // add paragraph
            newParagraph = {
              [time]: {
                speaker,
                time,
                words: {
                  [time]: textNewParagraph
                }
              }
            };
            selectIndex = index;
            added = true;
          } else {
            if (prev === index) {
              // next elements
              order
                .slice()
                .splice(i)
                .forEach(e => {
                  postElements[Number(e)] = element[Number(e)];
                });
              break;
            }
          }
        }
        prev = index;
      }
      if (added) {
        // merged elements in position
        const indexChange = !keyTime && last ? selectIndex + 1 : selectIndex;
        mergedTimeLine.splice(selectIndex, 1, postElements);
        mergedTimeLine.splice(indexChange, 0, newParagraph);
        if (Object.keys(prevElements).length > 0) {
          mergedTimeLine.splice(selectIndex, 0, prevElements);
        }
        break;
      }
    }
    return mergedTimeLine;
  };

  static newMark = (currentSentence: HTMLElement, initial: number, finish: number, confidence: number, marks: Marked) => {
    const markId = uuid4();
    const sentence = currentSentence.getAttribute('data-time');
    let text: string = '';
    Array.from(currentSentence.children).forEach(word => {
      const currentTime = parseFloat(word.getAttribute('data-time'));
      if (currentTime >= initial && currentTime <= finish) {
        text += word.innerHTML;
      }
    });
    const newMark = {
      text,
      sentence,
      initial: initial.toString(),
      final: finish.toString(),
      highlight: HightLightTypes.normal,
      id: markId,
      confidence
    };
    if (!marks[sentence]) {
      marks[sentence] = {};
    }
    marks[sentence][sentence + '-' + initial] = newMark;
    return marks;
  };

  static paragraphFromIntervention = (intervention: InterventionItem) => {
    const breakParagraph = parseInt(window._env_.REACT_APP_BREAK_PARAGRAPH);
    const paragraphs: TimeLineItem = [];
    let paragraph: EditorTimelineProperties = {};
    let newSentence: TimelineSpeaker = {
      speaker: intervention.speaker,
      time: 0,
      words: {}
    };
    let paragraphLength: number = 0;
    intervention.words.forEach((word, index) => {
      word.value = word.value.trim();
      const isBreakChar = DomHelper.isBreakChar(word.value);
      const timeParagraph: number = word.time;
      if (!isBreakChar && word.value !== ',') {
        word.value = ` ${word.value}`;
      }
      if (!paragraph[timeParagraph] && paragraphLength < 1) {
        //new paraprah & new sentece
        newSentence = {
          speaker: intervention.speaker,
          time: timeParagraph,
          words: {}
        };
        newSentence.words[timeParagraph] = word.value;
        paragraph[timeParagraph] = newSentence;
        paragraphLength += word.value.length;
      } else {
        // paragraph exists
        if (Object.keys(newSentence.words).length === 0) {
          // new sentence
          newSentence = {
            speaker: intervention.speaker,
            time: timeParagraph,
            words: {}
          };
          paragraph[timeParagraph] = newSentence;
        }
        newSentence.words[timeParagraph] = word.value;
        paragraphLength += word.value.length;
        if (index === intervention.words.length - 1) {
          // last word
          paragraphs.push(paragraph);
        } else {
          // breakParagraph
          if (isBreakChar) {
            newSentence = {
              speaker: intervention.speaker,
              time: timeParagraph,
              words: {}
            };
            if (paragraphLength >= breakParagraph) {
              paragraphLength = 0;
              paragraphs.push(paragraph);
              paragraph = {};
            }
          }
        }
      }
    });
    return paragraphs;
  };

  static pragraphsFromTranscription = (interventions: InterventionItem[]) => {
    const interventionsWithSpeaker = DomHelper.addSpeakersToWords(interventions);
    return interventionsWithSpeaker.map(intervention => {
      return DomHelper.paragraphFromIntervention(intervention);
    });
  };

  static scrollToElement = _.throttle((element: Element, smooth: boolean = true) => {
    if (element != null) {
      try {
        element.scrollIntoView({
          block: 'center',
          behavior: smooth ? 'smooth' : 'auto'
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, 100);

  static selectionNewMark = (elemInitial: HTMLElement, elemFinish: HTMLElement, docSel: Selection) => {
    const selection = docSel.toString().trim();
    let initial: number = parseFloat(elemInitial.outerHTML.split('"')[1]);
    let finish: number = parseFloat(elemFinish.outerHTML.split('"')[1]);

    if (_.isNaN(initial)) {
      initial = parseFloat(elemInitial.getAttribute('data-time'));
      finish = parseFloat(elemFinish.getAttribute('data-time'));
    }
    if (_.isNaN(initial) && elemInitial.nextSibling) {
      initial = parseFloat(elemInitial.nextSibling.parentElement.getAttribute('data-time'));
      finish = parseFloat(elemFinish.nextSibling.parentElement.getAttribute('data-time'));
    }
    if (_.isNaN(finish)) {
      const childs = docSel.focusNode.childNodes;
      for (let ii = childs.length - 1; ii >= 0; ii--) {
        const child = childs[ii] as HTMLElement;
        if (child.getAttribute('data-time')) {
          elemFinish = child.lastElementChild as HTMLElement;
          finish = parseFloat(elemFinish.getAttribute('data-time'));
          break;
        }
      }
    }
    if (finish < initial) {
      const temp: number = initial;
      initial = finish;
      finish = temp;
    }

    const list = DomHelper.getSentences(selection.replace('.', ' '));
    const currentSentence = elemInitial.parentElement;
    window.getSelection().removeAllRanges();
    return { list, currentSentence, initial, finish };
  };

  static setSentenceContentEditable = (time: number) => {
    const sentence = document.querySelector(`[data-time="${time}"][data-type="sentence"]`);
    sentence.setAttribute('contenteditable', 'true');
  };

  static wordsFromPaste = (sentence: Array<string>, time: number) => {
    const words: WordItemTranscription = sentence.map((word: string) => {
      const isBreakChar = DomHelper.isBreakChar(word);
      time = time + 1;
      return { value: isBreakChar ? word : ` ${word}`, time };
    });

    return { words, wordsTime: time };
  };
}
