import Box from '@material-ui/core/Box';
import _ from 'lodash';
import React, { Component, createRef, MouseEvent, RefObject, ReactElement, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  Document,
  Marked,
  Provider,
  Status,
  EditorTimelineProperties,
  TimelineSpeaker,
  Transcription,
  DocumentUpdate
} from '@newtral/editor-svc-client/esm';
import DomHelper from '../helpers/domHelper';
import { ClaimDetectionRequestInterface, ExtractURLServiceResponseInterface } from '../interfaces/index';
import { AppState } from '../store';
import * as editorActions from '../store/actions/editorActions';
import { MENU_HEIGHT, TOOLBAR_TO_EDITOR_MARGIN } from '../styles/constants';
import { FooterRefObject } from './Footer';
import { AlertsRefObject } from './Alert';
import NlpApi from '../helpers/api/nlpApi';
import { TranscriptionItem, markedItemInfoComparatorByInitial, TimeLineItem, SentencesItem } from '../store/types/documentTypes';
import { selectDocumentIdEditorAndProviderData } from '../store/selectors/documentSelectors';
import Loading from './Loading';
import { setDocument, setDocumentPartial } from '../store/actions/documentActions';
import { v4 as uuid4 } from 'uuid';
import SpotlightHelper from '../helpers/spotlightHelper';
import { Color } from '@material-ui/lab/Alert';
import Paragraph from './Paragraph';
import Speaker from './Speaker';
import { StoreHistory } from '../store/types/editorTypes';
import BackendApi from '../helpers/api/backendApi';

const mapStateToProps = (state: AppState) => ({
  ...state.editor,
  playbackRate: state.surfer.playbackRate,
  document: selectDocumentIdEditorAndProviderData(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({ ...editorActions, setDocument, setDocumentPartial }, dispatch)
});

interface AdditionalEditorProps {
  alertRef: RefObject<AlertsRefObject>;
  footerRef: RefObject<FooterRefObject>;
  hash: string;
  isAudio: boolean;
  setClaimToScrollTo: Function;
  setMarkedKaraokeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  updateDocument: Function;
  unsavedChanges: boolean;
  setLoading: (loading: boolean) => void;
}

interface AdditionalEditorState {
  editorLoading: boolean;
  editorStateTimeLine: TimeLineItem;
  editorStateMarks: Marked;
  textComponents: Array<ReactElement>;
  indexHistory: number;
}

type EditorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AdditionalEditorProps;

class Editor extends Component<EditorProps, AdditionalEditorState> {
  currentMarks: Element[] = [];
  innerEditorRef: React.RefObject<HTMLDivElement>;
  karaokeTimeout: NodeJS.Timeout;

  constructor(props: EditorProps) {
    super(props);
    this.innerEditorRef = createRef();
    this.state = {
      editorLoading: false,
      editorStateTimeLine: this.props.document.editorData.timeline,
      editorStateMarks: this.props.document.editorData.marks,
      textComponents: [],
      indexHistory: 0
    };
  }

  componentDidMount = () => {
    const intervalSave = setInterval(() => {
      if (this.props.unsavedChanges) {
        this.save();
      }
    }, 60000);
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (this.props.unsavedChanges) {
        this.save();
      } else {
        clearInterval(intervalSave);
      }
    };
    this.init();
  };

  init = async () => {
    this.setState({ editorLoading: true });
    if (this.getTranscription() == null && Object.keys(this.getTimeLine() ?? {}).length < 1) {
      this.blankDocumentPaste();
    } else {
      this.innerEditorRef.current.innerHTML = '';
      if (Object.keys(this.getTimeLine() ?? {}).length > 0) {
        await this.documentFromApi();
      } else {
        await this.documentFromProvider();
      }
    }
    DomHelper.editorStart(this.innerEditorRef.current);
    this.setState({ editorLoading: false });
    this.props.setLoading(false);
    setTimeout(() => {
      const mark = document.querySelector(`[data-id="${this.props.hash}"]`) as HTMLSpanElement;
      if (mark != null) {
        DomHelper.scrollToElement(mark);
        mark.click();
      }
    }, 1000);
  };

  /**
   * Load document
   */

  blankDocumentPaste = () => {
    const editText = this.innerEditorRef.current;
    editText.addEventListener(
      'paste',
      async (event: ClipboardEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const clipBoardData = event.clipboardData;
        const text: string = clipBoardData.getData('text/plain');
        await this.documentPaste(text);
      },
      { once: true }
    );
  };

  documentPaste = async (text: string) => {
    const editText = this.innerEditorRef.current;
    this.setState({ editorLoading: true });
    const nlpSentences = await NlpApi.sentencesService(text);
    if (nlpSentences == null || nlpSentences.error) {
      return this.showAlert(nlpSentences.error.message, 'error');
    }
    editText.innerHTML = '';
    editText.classList.remove('outline');
    editText.classList.add('outline-none');
    this.pasteToHtml(nlpSentences.sentences);
    this.save(true);
    this.setState({ editorLoading: false });
  };

  documentFromApi = async () => {
    await this.renderChanges(this.getTimeLine(), true);
    this.save();
  };

  documentFromProvider = async () => {
    await this.transcriptionToHtml();
    this.save(true);
  };

  documentFromURLProvider = async (document: Document) => {
    window.location.pathname = document._id;
  };

  documentFromPasteURL = async (extract: ExtractURLServiceResponseInterface, documentId: string, url: string) => {
    const updateDocumentDto: DocumentUpdate = {
      name: extract.metadata.title,
      provider: Provider.Medio,
      providerData: {
        id: url,
        metadata: extract.metadata
      },
      status: Status.InProgress
    };
    this.unmark();
    this.deleteTextComponents();
    await this.documentPaste(extract.text);
    setTimeout(async () => {
      const updatedDocument = await BackendApi.updateDocumentById(documentId, updateDocumentDto);
      await this.props.setDocumentPartial(updatedDocument);
    }, 1000);
  };

  getTranscription = (): Transcription => this.props.document.providerData?.transcription;

  getTimeLine = (): TimeLineItem => this.props.document.editorData?.timeline;

  getVideoController = (): FooterRefObject => this.props.footerRef.current;

  /**
   * Render components
   */

  deleteTextComponents = () => {
    this.setState({ textComponents: [] });
  };

  pasteToHtml = async (sentences: Array<string>) => {
    const interventions = DomHelper.interventionsFromPaste(sentences);
    const paragraphs = DomHelper.pragraphsFromTranscription(interventions).flat();
    await this.textComponents(paragraphs, true);
  };

  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (this.props.document.editorData !== nextProps.document.editorData) {
      return true;
    }
    if (this.state.editorLoading !== nextState.editorLoading) {
      return true;
    }
    return false;
  }

  renderChanges = async (timeline: TimeLineItem, save: boolean = false) => {
    await this.transcriptionToHtml(timeline, save);
    this.formatMarked();
  };

  transcriptionToHtml = async (timeline?: TimeLineItem, save: boolean = false) => {
    if (timeline == null) {
      const { interventions } = this.getTranscription();
      timeline = DomHelper.pragraphsFromTranscription(interventions).flat();
    }
    await this.textComponents(timeline, save);
  };

  textComponents = async (paragraphs: TimeLineItem, save?: boolean) => {
    let hash = uuid4();
    let previousSpeaker: string = null;
    const textComponents = paragraphs.map((paragraph: EditorTimelineProperties) => {
      const sorted = _.sortBy(paragraph, 'time');
      const sentence: TimelineSpeaker = sorted.slice().shift();
      const time = Number(Object.keys(sentence.words).shift());
      const speaker: TranscriptionItem = { value: sentence.speaker, time };
      let speakerComponent = null;

      if (previousSpeaker !== speaker.value) {
        hash = uuid4();
        previousSpeaker = speaker.value;
        speakerComponent = this.newSpeaker(speaker, hash);
      }

      return (
        <React.Fragment key={`${time}_${sentence.speaker}_${hash}`}>
          {speakerComponent}
          {this.newParagraph(speaker, sorted, time, hash)}
        </React.Fragment>
      );
    });

    this.setState({ editorStateTimeLine: paragraphs });
    this.setState({ textComponents });
    await this.props.setDocumentPartial({ editorData: { timeline: paragraphs } });
    if (!!save) {
      this.saveHistory();
    }
  };

  /**
   * Edit functions
   */

  addParagraph = async (event: MouseEvent, speaker: string = null) => {
    event.preventDefault();
    event.stopPropagation();
    const currentAddButton = event.currentTarget as HTMLElement;
    const currentParagraph = currentAddButton.parentElement;
    let time: number;
    if (!speaker) {
      time = DomHelper.getLastWordChildParagrah(currentParagraph) + 0.01;
      speaker = currentParagraph.getAttribute('data-speaker');
    } else {
      const currentHash = currentParagraph.getAttribute('data-hash');
      const elements = Array.from(document.querySelectorAll(`[data-hash="${currentHash}"]`));
      const paragraph = elements
        .filter(e => {
          return e.getAttribute('data-type') === 'paragraph';
        })
        .pop();
      speaker = `${speaker}_${DomHelper.hashSpeaker()}`;
      time = DomHelper.getLastWordChildParagrah(paragraph as HTMLElement) + 0.01;
    }
    time = Number(time.toFixed(2));
    const editorTimeLine: TimeLineItem = JSON.parse(JSON.stringify([...this.state.editorStateTimeLine])) ?? [];
    const mergedTimeLine = DomHelper.mergeNewParagraph(editorTimeLine, speaker, time);
    await this.renderChanges(mergedTimeLine, true);
  };

  addSpeaker = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.addParagraph(event, 'S');
  };

  handleKeyDown = _.throttle(() => {
    if (!this.props.unsavedChanges) {
      this.props.setUnsavedChanges(true);
    }
  }, 2000);

  playSentence = (start: number, end: number) => {
    this.props.footerRef.current?.playSentence(start, end);
  };

  pause = () => {
    this.props.footerRef.current?.pause();
  };

  mouseUp = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      this.pause();
      SpotlightHelper.cleanSpotlightedText(this.innerEditorRef.current);
      this.props.setClaimToScrollTo(null);
      const docSel = document.getSelection();
      if (docSel.anchorNode != null && this.props.highlight) {
        const selection = docSel.toString().trim();
        let elemInitial: HTMLElement = docSel.anchorNode.parentElement;
        if (elemInitial.getAttribute('class') === 'edit-text') {
          elemInitial = docSel.anchorNode.nextSibling.firstChild.firstChild as HTMLElement;
        }
        let elemFinish: HTMLElement = docSel.focusNode.parentElement;

        if (selection.length > 1 && !elemInitial.getAttribute('data-mark')) {
          this.selectionNewMark(elemInitial, elemFinish, docSel);
        }
        this.scrollToMark(elemInitial);
      }
    } catch (error) {
      console.error(error);
    }
  };

  newMark = (sentence: HTMLElement, initial: number, finish: number) => {
    const marks = DomHelper.newMark(sentence, initial, finish, 1, this.props.document.editorData.marks);
    this.formatMarked(marks);
    this.addToHistory();
  };

  newParagraph = (speaker: TranscriptionItem, paragraph: SentencesItem, time: number, hash: string) => {
    return (
      <Paragraph
        key={`paragraph_${time}__${speaker.value}`}
        text={paragraph}
        hash={hash}
        speaker={speaker}
        add={this.addParagraph}
        remove={this.removeParagraph}
        mouseUp={this.mouseUp}
        playSentence={this.playSentence}
        renderChanges={this.renderChanges}
      />
    );
  };

  newSpeaker = (speaker: TranscriptionItem, hash: string) => {
    return (
      <Speaker
        key={`speaker_${speaker.time}`}
        hash={hash}
        speaker={speaker}
        add={this.addSpeaker}
        remove={this.removeSpeaker}
        renderChanges={this.renderChanges}
      />
    );
  };

  removeParagraph = async (event: MouseEvent, speaker?: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    const currentRemoveButton = event.currentTarget as HTMLElement;
    const currentParagraph = currentRemoveButton.parentElement;
    const hash = currentParagraph.getAttribute('data-hash');
    const hashSibling = document.querySelectorAll(`[data-hash="${hash}"]`);
    const time = currentParagraph.getAttribute('data-time');
    if (hashSibling.length < 3 && !speaker) {
      this.showAlert('Puedes eliminar el parrafo actual, eliminando el speaker', 'info');
      return false;
    }
    const editorTimeLine = JSON.parse(JSON.stringify([...this.state.editorStateTimeLine])) ?? [];
    editorTimeLine.forEach((paragraph: EditorTimelineProperties, index: number) => {
      if (paragraph[Number(time)]) {
        editorTimeLine.splice(index, 1);
      }
    });
    await this.renderChanges(editorTimeLine, true);
  };

  removeSpeaker = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (document.querySelectorAll('.speaker').length < 2) {
      this.showAlert('No se pude eliminar el speaker actual', 'info');
      return false;
    }
    this.removeParagraph(event, true);
  };

  /**
   * History functions
   */

  addToHistory = () => {
    const editText: HTMLElement = this.innerEditorRef.current;
    const timeline = JSON.parse(JSON.stringify([...this.state.editorStateTimeLine])) ?? [];
    const currentMarks = JSON.parse(JSON.stringify({ ...this.state.editorStateMarks })) ?? {};
    const marks = JSON.parse(JSON.stringify({ ...this.props.document.editorData.marks })) ?? {};
    const history: StoreHistory = JSON.parse(JSON.stringify([...this.props.history])) ?? [];
    if ((editText && Object.keys(timeline).length > 0) || JSON.stringify(marks) !== JSON.stringify(currentMarks)) {
      if (history.length > 10) {
        history.splice(0, 1);
      }
      if (Number(this.state.indexHistory) !== history.length - 1) {
        history.splice(Number(this.state.indexHistory) + 1);
      }
      history.push({
        timeline,
        marks
      });
      this.setState({ indexHistory: history.length - 1 });
      this.props.setHistory(history);
      this.props.setUnsavedChanges(true);
    }
  };

  redoHistory = async () => {
    const history: StoreHistory = JSON.parse(JSON.stringify([...this.props.history])) ?? [];
    if (this.state.indexHistory + 1 <= history.length - 1) {
      const redo = Number(this.state.indexHistory) + 1;
      this.setState({ indexHistory: redo });
      this.formatMarked(history[redo].marks);
      await this.renderChanges(history[redo].timeline);
      this.props.setUnsavedChanges(true);
    }
  };

  save = (history?: boolean) => {
    const editText = this.innerEditorRef.current;
    const currentHtml = this.state.editorStateTimeLine;
    if (editText && Object.keys(currentHtml).length > 0) {
      SpotlightHelper.cleanSpotlightedText(editText);
      if (!!history) {
        this.saveHistory();
      }
      this.props.updateDocument();
    }
  };

  saveHistory = () => {
    this.addToHistory();
    this.formatMarked();
  };

  undoHistory = async () => {
    const history: StoreHistory = JSON.parse(JSON.stringify([...this.props.history])) ?? [];
    if (this.state.indexHistory > 0 && this.state.indexHistory <= history.length - 1) {
      const undo = Number(this.state.indexHistory) - 1;
      this.setState({ indexHistory: undo });
      this.formatMarked(history[undo].marks);
      await this.renderChanges(history[undo].timeline);
      this.props.setUnsavedChanges(true);
    }
  };

  /**
   * Marks functions
   */

  getNextMarkFromTime = (marks: Element[], lastMarkTime?: number) => {
    if (!lastMarkTime) {
      lastMarkTime = marks.length > 0 ? Number(marks[marks.length - 1].getAttribute('data-time')) : 0;
    }
    const nextMark = Object.values(this.props.document.editorData.marks)
      .reduce((prev, current) => prev.concat(Object.values(current)), [])
      .sort(markedItemInfoComparatorByInitial)
      .find(e => Number(e.initial) > lastMarkTime);
    return nextMark;
  };
  getNextMarks = (marks: Element[]) => {
    let nextMark = this.getNextMarkFromTime(marks);
    if (nextMark && DomHelper.getMarks(nextMark.id).length < 1) {
      nextMark = this.getNextMarkFromTime(marks, Number(nextMark.final));
    }
    return nextMark ? DomHelper.getMarks(nextMark.id) : null;
  };

  formatMarked = (marks?: Marked) => {
    if (!marks) {
      marks = this.props.document.editorData.marks;
    }
    DomHelper.formatMarked(marks);
    this.props.setDocumentPartial({ editorData: { marks } });
    this.props.setUnsavedChanges(true);
  };

  markFromData = async () => {
    this.setState({ editorLoading: true });
    this.props.setHighlightStatus(!this.props.highlight);
    const editTextElement: HTMLElement = this.innerEditorRef.current;
    editTextElement.style.display = 'none';
    const textData: Array<string> = DomHelper.getSentences();
    const payload: ClaimDetectionRequestInterface = {
      claims: textData
    };
    const nlpClaims = await NlpApi.claimDetection(payload);
    if (nlpClaims == null || nlpClaims.error) {
      editTextElement.style.display = 'block';
      this.props.setHighlightStatus(!this.props.highlight);
      return this.showAlert(nlpClaims.error.message, 'error');
    }
    const marks = DomHelper.markFromData(nlpClaims, this.props.document.editorData.marks);
    this.formatMarked(marks);
    editTextElement.style.display = 'block';
    this.props.setHighlightStatus(!this.props.highlight);
    this.save();
    this.setState({ editorLoading: false });
  };

  scrollToMark = (elemInitial: HTMLElement) => {
    if (elemInitial.getAttribute('data-mark')) {
      setTimeout(() => {
        this.setCurrentCard(elemInitial.getAttribute('data-mark'));
        this.props.setClaimToScrollTo({
          initial: `${elemInitial.parentElement.getAttribute('data-time')}`,
          sentence: `${elemInitial.parentElement.parentElement.getAttribute('data-time')}`
        });
      }, 500);
    }
  };

  selectionNewMark = (elemInitial: HTMLElement, elemFinish: HTMLElement, docSel: Selection) => {
    const { list, currentSentence, initial, finish } = DomHelper.selectionNewMark(elemInitial, elemFinish, docSel);
    if (list.length > 0) {
      this.newMark(currentSentence, initial, finish);
      this.save();
    }
  };

  setCurrentCard = (id: string) => {
    this.props.setCurrentCard(id);
  };

  setCurrentMarks = (marks: Element[]) => {
    this.currentMarks = marks;
  };

  unmark = () => {
    DomHelper.deleteMark();
    SpotlightHelper.cleanSpotlightedText(this.innerEditorRef.current);
    this.props.setDocumentPartial({ editorData: { marks: {} } });
    this.props.setUnsavedChanges(true);
  };

  /**
   * Karaoke functions
   */

  getNextSentenceFromSentenceElement = (sentence: Element): Element => {
    return DomHelper.getNextSentenceFromSentenceElement(sentence);
  };

  getSentenceFromTime = (time: number): Element => {
    return DomHelper.getSentenceFromTime(time);
  };

  karaokeStart = (time: number) => {
    this.currentMarks = [];
    SpotlightHelper.cleanSpotlightedText(this.innerEditorRef.current);
    const sentence = this.getSentenceFromTime(time);
    SpotlightHelper.spotlightSentenceWithNext(
      time,
      sentence,
      this.getNextSentenceFromSentenceElement,
      this.setKaraokeTimeout,
      this.props.playbackRate
    );
  };

  karaokeStop = () => {
    this.props.setMarkedKaraokeEnabled(false);
    SpotlightHelper.cleanSpotlightedText(this.innerEditorRef.current);
    clearTimeout(this.karaokeTimeout);
    this.karaokeTimeout = null;
  };

  markedKaraokeStart = () => {
    SpotlightHelper.cleanSpotlightedText(this.innerEditorRef.current);
    clearTimeout(this.karaokeTimeout);
    this.karaokeTimeout = null;
    this.props.footerRef.current.play(false);
    const marks = this.currentMarks.length > 0 ? this.currentMarks : this.getNextMarks(this.currentMarks);
    SpotlightHelper.spotlightMarkWithNext(
      marks,
      this.getNextMarks,
      this.getNextSentenceFromSentenceElement,
      this.setKaraokeTimeout,
      this.props.playbackRate,
      this.getVideoController(),
      this.setCurrentMarks,
      this.markedKaraokeStop,
      this.setCurrentCard
    );
  };

  markedKaraokeStop = () => {
    this.props.footerRef.current.pause();
  };

  setKaraokeTimeout = (newTimeout: NodeJS.Timeout) => (this.karaokeTimeout = newTimeout);

  /**
   * showAlert function
   */

  showAlert = (text: string, color: Color) => {
    this.props.alertRef.current.showAlert(text, color);
    const editTextElement: HTMLElement = this.innerEditorRef.current;
    editTextElement.style.display = 'block';
    this.setState({ editorLoading: false });
    return;
  };

  render() {
    return (
      <Box
        className="editor"
        style={
          !this.props.isAudio
            ? {
                height: `calc(100% - ${TOOLBAR_TO_EDITOR_MARGIN}px - ${MENU_HEIGHT}px - 10px`
              }
            : {}
        }
      >
        <Box className="editBox">
          <Loading display={this.state.editorLoading} />
          {/* MaterialUI Box component lacks ref property in the typescript definition, so it was replaced with regular div */}
          <div onKeyDown={this.handleKeyDown} ref={this.innerEditorRef} id="edit-text" className="edit-text outline">
            <Box className="outline" contentEditable></Box>
            {this.state.textComponents}
          </div>
        </Box>
      </Box>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(memo(Editor));
