import React, {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  MouseEvent,
  useState,
  Ref,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux';
import { setDialogAction, setDialogURL, setUser } from '../store/actions/editorActions';
import { dialogActions, DialogItem } from '../store/types/editorTypes';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Input, makeStyles, Theme } from '@material-ui/core';
import { User } from '@newtral/editor-svc-client/esm/types/users';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    color: '#333333'
  },
  select: {
    width: '400px'
  }
}));

export interface DialogRefObject {
  handleSetDialog: (newDialog: DialogItem) => void;
}

interface DialogAlertProps {
  status: boolean;
}

const DialogAlert = ({ status }: DialogAlertProps, ref: Ref<DialogRefObject>) => {
  const [action, setAction] = useState('');
  const [open, setOpen] = useState(status);
  const [inputUrl, setInputUrl] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [disable, setDisable] = useState(true);
  const [url, setURL] = useState('');
  const [dialog, setDialog] = useState({ action: '', description: '', message: '' });
  const [userList, setUserList] = useState({ users: [] });
  const [currentUser, setCurrentUser] = useState({});
  const [personName, setPersonName] = useState('');
  const classes = useStyles({});
  const dispatch = useDispatch();

  const reset = () => {
    setOpen(false);
    setInputError(false);
    setDisable(true);
    setURL('');
    setUserList({ users: [] });
  };

  const handleSetDialog = (newDialog: DialogItem) => {
    setDialog(newDialog);
    setAction(newDialog.action);
    setInputUrl(newDialog.url);
    setUserList(newDialog.users);
    setOpen(true);
  };

  const handleClickAction = useCallback(() => {
    if (dialog.action === dialogActions.dialogSentences) {
      dispatch(setDialogAction(dialogActions.sendSentences));
      return reset();
    }
    dispatch(setDialogAction(action));
    dispatch(setDialogURL(url));
    reset();
  }, [action, dialog.action, dispatch, url]);

  const handleClose = useCallback(() => {
    if (dialog.action === dialogActions.dialogSentences && Object.keys(currentUser).length > 1) {
      dispatch(setDialogAction(action));
    } else {
      dispatch(setDialogAction(`${action}Close`));
    }
    reset();
  }, [action, currentUser, dialog.action, dispatch]);

  const handleKeyEnter = (e: KeyboardEvent<HTMLElement>) => {
    if (e.which === 13 && !inputError && !disable) {
      handleClickAction();
    }
  };

  const validateURL = (url: string) => {
    try {
      setURL(url);
      new URL(url);
      const urlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
      if (!urlPattern.test(url)) {
        return setInputError(true);
      }
      setDisable(false);
      return setInputError(false);
    } catch (_) {
      return setInputError(true);
    }
  };

  const validateEmptyURL = () => {
    if (url.length > 1) {
      return setDisable(false);
    }
    return setDisable(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    validateURL(e.currentTarget.value);
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const field = e.target as HTMLInputElement;
    validateURL(field.value);
  };

  const validateUser = () => {
    if (Object.keys(currentUser).length > 1) {
      setDisable(false);
      return setInputError(false);
    }
    return setInputError(true);
  };

  const handleChangeUsers = useCallback(
    (e: ChangeEvent<{ value: unknown }>) => {
      e.preventDefault();
      e.stopPropagation();
      const selectedUser = e.currentTarget as HTMLInputElement;
      const user: User = JSON.parse(selectedUser.getAttribute('data-user'));
      setPersonName(user.realName);
      setCurrentUser(user);
      dispatch(setUser(user));
    },
    [dispatch]
  );

  const validateFields = (e: MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (inputUrl) {
      validateEmptyURL();
    }
    if (userList) {
      validateUser();
    }
  };

  const disableAction = () => {
    if (userList && disable) {
      return true;
    }
    if (inputUrl && (inputError || disable)) {
      return true;
    }
  };

  useImperativeHandle(ref, () => ({
    handleSetDialog
  }));

  return (
    <div>
      <Dialog maxWidth={inputUrl ? 'sm' : 'xs'} fullWidth open={open} onClose={handleClose}>
        <DialogTitle>{dialog.message}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.description}> {dialog.description} </DialogContentText>
          {inputUrl && (
            // dialog paste url
            <TextField
              error={inputError}
              onKeyPress={handleKeyEnter}
              onChange={handleChange}
              onPaste={handlePaste}
              autoFocus
              margin="dense"
              label="URL"
              type="url"
              placeholder="https://example.com"
              fullWidth
              helperText={inputError ? 'Debe contener una url válida' : ''}
            />
          )}
          {userList && (
            // dialog select user
            <FormControl>
              <InputLabel>Selecciona tu usuario</InputLabel>
              <Select error={inputError} className={classes.select} value={personName} onChange={handleChangeUsers} input={<Input />}>
                {userList.users
                  .sort((a, b) => a.realName.localeCompare(b.realName))
                  .filter(user => user.id !== 'USLACKBOT')
                  .map((user: User) => (
                    <MenuItem key={user.name} data-user={JSON.stringify(user)} value={user.realName}>
                      {user.realName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions onMouseOver={validateFields}>
          <Button onClick={handleClose} color="primary">
            {dialog.action === dialogActions.dialogSentences && 'No enviar'}
            {dialog.action === dialogActions.deleteDocument && 'Cancelar'}
          </Button>
          <Button onClick={handleClickAction} color="primary" disabled={disableAction()}>
            {dialog.action === dialogActions.dialogSentences && 'Enviar'}
            {dialog.action !== dialogActions.dialogSentences && 'Aceptar'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default forwardRef<DialogRefObject, DialogAlertProps>(DialogAlert);
