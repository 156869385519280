// State interfaces

import { Marked, MarkedItemInfo } from '@newtral/editor-svc-client/esm';
import { TimeLineItem } from "./documentTypes";

export enum ClaimbotAlgorithm {
  CLAIM = "Algoritmo Claim",
  DATA = "Algoritmo Data"
}

export enum dialogActions {
  deleteDocument = 'deleteDocument',
  pasteURL = 'pasteURL',
  selectUser = 'selectUser',
  selectUserClose = 'selectUserClose',
  dialogSentences = 'dialogSentences',
  sendSentences = 'sendSentences',
  sendSentencesClose = 'sendSentencesClose'
}

export interface EditorState {
  highlight: boolean;
  claimbotAlgorithm: ClaimbotAlgorithm;
  history: StoreHistory;
  card: string;
  mark: MarkedItemInfo | null;
  editing: string;
  action: string;
  url: string;
  user: User | {};
}

export interface StoreHistoryItem {
  timeline: TimeLineItem;
  marks: Marked;
}

export type StoreHistory = StoreHistoryItem[];

export interface DialogItem {
  action: string;
  description: string;
  message: string;
  url?: boolean;
  users?: Users;
}

export interface User {
  id: string;
  name: string;
  realName: string;
  avatar: string;
  email?: string;
}

export interface Users {
  users: User[];
}


// Action name constants

export const SET_BUTTON_HIGHLIGHT = "SET_BUTTON_HIGHLIGHT";
export const SET_BUTTON_EDIT = "SET_BUTTON_EDIT";
export const SET_HIGHLIGHT_STATUS = "SET_HIGHLIGHT_STATUS";
export const SET_CLAIMBOT_ALGORITHM = "SET_CLAIMBOT_ALGORITHM";
export const SET_HISTORY = "SET_HISTORY";
export const SET_CARD = "SET_CARD";
export const SET_MARK = "SET_MARK";
export const SET_CARD_EDITING = "SET_CARD_EDITING";
export const SET_DIALOG_ACTION = "SET_DIALOG_ACTION";
export const SET_DIALOG_URL = "SET_DIALOG_URL";
export const SET_USER = "SET_USER";

// Action interfaces

export interface SetHighlightStatusAction {
  type: typeof SET_HIGHLIGHT_STATUS;
  highlight: boolean;
}

export interface SetClaimbotAlgorithmAction {
  type: typeof SET_CLAIMBOT_ALGORITHM;
  claimbotAlgorithm: ClaimbotAlgorithm;
}

export interface SetHistory {
  type: typeof SET_HISTORY;
  history: StoreHistory;
}

export interface SetCurrentCard {
  type: typeof SET_CARD;
  card: string;
}

export interface SetCurrentMark {
  type: typeof SET_MARK;
  mark: MarkedItemInfo | null;
}

export interface SetCurrentCardEditing {
  type: typeof SET_CARD_EDITING;
  editing: string | null;
}

export interface SetDialogAction {
  type: typeof SET_DIALOG_ACTION;
  action: string;
}

export interface SetDialogURL {
  type: typeof SET_DIALOG_URL;
  url: string;
}

export interface SetUser {
  type: typeof SET_USER;
  user: User;
}

export type EditorActionTypes =
  | SetHighlightStatusAction
  | SetClaimbotAlgorithmAction
  | SetCurrentCard
  | SetCurrentMark
  | SetCurrentCardEditing
  | SetDialogAction
  | SetDialogURL
  | SetUser
  | SetHistory;
