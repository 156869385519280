import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AudioPeaks } from '@newtral/editor-svc-client/esm';
import React, {
  ChangeEvent,
  forwardRef,
  Ref,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { VideoPlayerStatus } from "../interfaces";
import {
  setDuration,
  setPaused,
  setPlaybackRate,
  setTime
} from "../store/actions/surferActions";
import {
  selectDocumentAudioUrl,
  selectDocumentVideoUrl
} from "../store/selectors/documentSelectors";
import { selectSurfer } from "../store/selectors/surferSelectors";
import { SURFER_HEIGHT } from "../styles/constants";
import Editor from "./Editor";
import Surfer, { SurferRefObject } from "./Surfer";
import SurferMenu from "./SurferMenu";
import Video from "./Video";
import DomHelper from "../helpers/domHelper";
import { setCurrentCard, setCurrentCardEditing } from "../store/actions/editorActions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    left: 0,
    right: 0,
    width: "100vw",
    height: SURFER_HEIGHT,
    bottom: 0,
    backgroundColor: "white",
    borderTop: "2px solid #e0e0e0",
    zIndex: 1
  }
}));

let timePlay:NodeJS.Timeout = null;

interface FooterProps {
  isVideo: boolean;
  editorRef: React.RefObject<typeof Editor.WrappedComponent.prototype>;
  getPeaksByDocumentId: () => Promise<AudioPeaks>;
  createPeaksByDocumentId: (peaks: AudioPeaks) => Promise<AudioPeaks>;
}

export interface FooterRefObject {
  play: (karaoke?: boolean, time?:number) => void;
  playSentence: (start:number, end:number) => void;
  pause: (karaoke?: boolean) => void;
  seek: (time: number, karaoke?: boolean) => void;
  forward: (karaoke?: boolean) => void;
  rewind: (karaoke?: boolean) => void;
}

const Footer = ({ isVideo, editorRef, getPeaksByDocumentId, createPeaksByDocumentId }: FooterProps, ref: Ref<FooterRefObject>) => {
  const classes = useStyles({});
  const surferState = useSelector(selectSurfer);
  const audioUrl = useSelector(selectDocumentAudioUrl);
  const videoUrl = useSelector(selectDocumentVideoUrl);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const surferRef: RefObject<SurferRefObject> = useRef(null);

  useEffect(() => {
    const unsubscribe = videoRef.current.subscribeToStateChange(
      (state: VideoPlayerStatus) => {
        if (surferState.time !== state.currentTime) {
          dispatch(setTime(state.currentTime));
        }
        if (surferState.duration !== state.duration) {
          dispatch(setDuration(state.duration));
        }
        if (surferState.paused !== state.paused) {
          dispatch(setPaused(state.paused));
        }
      }
    );
    return unsubscribe;
  }, [
    dispatch,
    surferState.duration,
    surferState.paused,
    surferState.time,
    videoRef
  ]);

  const play = (karaoke = true, time?:number) => {
    dispatch(setCurrentCard(""));
    dispatch(setCurrentCardEditing(null));
    videoRef.current.play();
    if(time == null){
      time = surferState.time === surferState.duration ? 0 : surferState.time;
    }
    if(surferRef.current?.waveSurferInstance?.current){
      surferRef.current.waveSurferInstance.current.play(time);
    }
    if (karaoke) {
      editorRef.current.karaokeStart(time);
    }
    surferRef.current.waveSurferInstance.current.on("pause",()=>{
      clearTimeout(timePlay);
    });
  };

  const playSentence = (start: number, end:number, karaoke = true) => {
    clearTimeout(timePlay);
    pause();
    setTimeout(()=>{
      timePlay = setTimeout(()=>{
        pause();
      }, ((end-start)*1000)+100);
      videoRef.current.seek(start);
      play(true, start);
    },500);
  };

  const pause = (karaoke = true) => {
    dispatch(setCurrentCard(""));
    videoRef.current.pause();
    if(surferRef.current?.waveSurferInstance?.current){
      clearTimeout(timePlay);
      surferRef.current.waveSurferInstance.current.pause();
    }
    if (karaoke) {
      editorRef.current.karaokeStop();
    }
  };

  const seek = (time: number, karaoke = true) => {
    const timeToSeek = time < 0 ? 0 : time > surferState.duration ? 0 : time;
    videoRef.current.seek(timeToSeek);
    DomHelper.scrollToElement(document.querySelector(`[data-time^="${Math.round(timeToSeek)}"]`));
    if(surferRef.current?.waveSurferInstance?.current){
      surferRef.current.waveSurferInstance.current.play(timeToSeek);
      if (surferState.paused) {
          setTimeout(()=>{
            surferRef.current.waveSurferInstance.current.pause();
          },200);
      } else if (karaoke) {
        editorRef.current.karaokeStop();
        editorRef.current.karaokeStart(timeToSeek);
      }
    }
  };

  const forward = (karaoke = true) => {
    seek(surferState.time + 5, karaoke);
  };

  const rewind = (karaoke = true) => {
    seek(surferState.time - 5, karaoke);
  };

  const changePlaybackRate = (value: number): void => {
    if(surferRef.current?.waveSurferInstance?.current){
      surferRef.current.waveSurferInstance.current.setPlaybackRate(value);
    }
    videoRef.current.playbackRate = value;
    dispatch(setPlaybackRate(value));
  };

  const handleChangePlaybackRate = (
    event: ChangeEvent<{}>,
    value: number | number[]
  ): void => {
    const speed: number = value as number;
    changePlaybackRate(speed);
  };

  useImperativeHandle(ref, () => ({
    play,
    playSentence,
    pause,
    seek,
    forward,
    rewind,
    changePlaybackRate
  }));

  return (
    <Box className={classes.root}>
      <Video ref={videoRef} source={isVideo ? videoUrl : audioUrl} audio={audioUrl}/>
      <Surfer
        isVideo={isVideo}
        ref={surferRef}
        footerRef={ref as RefObject<FooterRefObject>}
        source={audioUrl}
        getPeaksByDocumentId={getPeaksByDocumentId}
        createPeaksByDocumentId={createPeaksByDocumentId}
      />
      <SurferMenu
        editorRef={editorRef}
        playbackRate={surferState.playbackRate}
        currentTime={surferState.time}
        isPlaying={!surferState.paused}
        duration={surferState.duration}
        changePlaybackRate={handleChangePlaybackRate}
        playVideo={play}
        pauseVideo={pause}
        videoForward={forward}
        videoRewind={rewind}
      />
    </Box>
  );
};

export default forwardRef<FooterRefObject, FooterProps>(Footer);
