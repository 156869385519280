import { Box } from "@material-ui/core";
import { 
  AudioPeaks, 
  DocumentUpdate, 
  MarkedItemInfo 
} from '@newtral/editor-svc-client/esm';
import React, { useRef, useCallback, useState, useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { VideomaAllMetadata } from '../store/types/documentTypes';
import Alerts from "./Alert";
import Editor from "./Editor";
import Footer from "./Footer";
import Header from "./Header";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { selectDocument, selectDocumentHash } from "../store/selectors/documentSelectors";
import { useSelector, useDispatch } from "react-redux";
import BackendApi from "../helpers/api/backendApi";
import { setDocument, setDocumentHash } from "../store/actions/documentActions";
import Loading from "./Loading";
import DialogAlert from './Dialog';

interface RouteParams {
  id: string;
}

const App = ({ match, location }: RouteComponentProps<RouteParams>) => {
  const footerRef = useRef(null);
  const alertRef = useRef(null);
  const dialogRef = useRef(null);
  const editorRef = useRef(null);
  const [deleted, setDeleted] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const hash = useSelector(selectDocumentHash);
  const [markedKaraokeEnabled, setMarkedKaraokeEnabled] = useState(false);
  const [claimToScrollTo, setClaimToScrollTo] = useState<
    Partial<MarkedItemInfo>
  >(null);
  const document = useSelector(selectDocument);
  const dispatch = useDispatch();

  useEffect(() => {
    if(hash === ""){
      dispatch(setDocumentHash(location.hash.replace("#", "")));
    }
  }, [dispatch, hash, location]);

  const updateDocument = useCallback(async () => {
    setUnsavedChanges(false);
    setSaving(true);
    const updateDocumentDto: DocumentUpdate = {
      name: document.name,
      status: document.status,
      editorData: {
        marks: document.editorData.marks,
        timeline: document.editorData.timeline,
        botmode: document.editorData.botmode,
        user: document.editorData.user
      }
    };
    const updatedDocument = await BackendApi.updateDocumentById<VideomaAllMetadata>(
      document._id,
      updateDocumentDto
    );
    setSaving(false);
    dispatch(setDocument(updatedDocument));
  }, [dispatch, document]);

  const deleteDocument = useCallback(async () => {
    setUnsavedChanges(false);
    setSaving(false);
    setDeleted(true);
    dispatch(setDocument(null));
    await BackendApi.deleteDocumentById(document._id);
  }, [dispatch, document]);

  const getPeaksByDocumentId = () => {
    return BackendApi.getDocumentAudioPeaks(document._id);
  };
  const createPeaksByDocumentId = (peaks: AudioPeaks) => {
    return BackendApi.createDocumentAudioPeaks(document._id, peaks);
  };

  let result: JSX.Element;

  if (document == null) {
    const url = deleted ? "/" : `/document/${match.params.id}${location.hash}`;
    result = <Redirect to={url} />;
  } else {
    const isVideo = document.providerData?.videoUrl != null;
    const isAudio = document.providerData?.audioUrl != null;
    const surfer: JSX.Element = isAudio ? (
      <Footer 
        isVideo={isVideo}
        ref={footerRef} 
        editorRef={editorRef}
        getPeaksByDocumentId={getPeaksByDocumentId}
        createPeaksByDocumentId={createPeaksByDocumentId}
      />
    ) : null;
    return (
      <Box className="app" id="app">
        <Alerts ref={alertRef} show={false}/>
        <DialogAlert ref={dialogRef} status={false} />
        <Header
          dialogRef={dialogRef}
          editorRef={editorRef}
          deleteDocument={deleteDocument}
          isVideo={isVideo}
          setUnsavedChanges={setUnsavedChanges}
        />
        <LeftPanel
          editorRef={editorRef}
          saving={saving}
          unsavedChanges={unsavedChanges}
        />
        <Loading display={loading} />
        <Editor
          // @ts-ignore
          ref={editorRef}
          hash={hash}
          isAudio={isAudio}
          footerRef={footerRef}
          alertRef={alertRef}
          setLoading={setLoading}
          updateDocument={updateDocument}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          setClaimToScrollTo={setClaimToScrollTo}
          setMarkedKaraokeEnabled={setMarkedKaraokeEnabled}
        />
        <RightPanel
          editorRef={editorRef}
          isAudio={isAudio}
          setUnsavedChanges={setUnsavedChanges}
          markedToScrollTo={claimToScrollTo}
          setMarkedToScrollTo={setClaimToScrollTo}
          markedKaraokeEnabled={markedKaraokeEnabled}
          setMarkedKaraokeEnabled={setMarkedKaraokeEnabled}
        />
        {surfer}
      </Box>
    );
  }
  return result;
};

export default App;
