import { NlpClient } from '@newtral/nlp-svc-client/esm';
import axios from 'axios';
import { ClaimDetectionRequestInterface, ClaimDetectionResponseInterface, SentencesServiceResponseInterface } from '../../interfaces';

export default class NlpApi {
  private static nlpClient?: NlpClient;

  static async sentencesService(editText: string): Promise<SentencesServiceResponseInterface> {
    if (!this.nlpClient) {
      this.nlpClient = new NlpClient(window._env_.REACT_APP_NLP_SVC_URL);
    }

    try {
      const sentences = await this.nlpClient.splitTextIntoSentences(editText, 'spacy');
      return { sentences };
    } catch (err) {
      return { error: { status: err.response?.status, message: `sentencesService: ${err.message}` } };
    }
  }

  static async claimDetection(payload: ClaimDetectionRequestInterface): Promise<ClaimDetectionResponseInterface> {
    try {
      const language = 'es';
      const domain = 'congress';
      const metric = 'trawl';
      const response = await axios({
        url: `${window._env_.REACT_APP_CLAIM_DETECTION}?language=${language}&domain=${domain}&metric=${metric}`,
        method: 'post',
        data: payload
      });
      return response.data;
    } catch (err) {
      return { error: { status: err.response?.status, message: `claimDetection: ${err.message}` } };
    }
  }
}
