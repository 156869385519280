import React, { useState, useCallback, useRef } from "react";
import {
  ButtonGroup,
  Button,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
  Typography,
  Theme,
  Divider,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    height: "100%",
    color: "black",
    borderRadius: 0
  },
  dropdownButton: {
    border: "unset",
    "&:disabled": {
      border: "unset"
    }
  },
  dropdownButtonArrow: {
    "&:disabled": {
      border: "unset"
    },
    backgroundColor: "#e0e0e0",
    border: "unset",
    padding: 0,
    minWidth: 24,
    marginLeft: "0 !important"
  },
  dropdownMenuList: {
    border: "1px solid #e2e2e2"
  },
  divider: {
    backgroundColor: "#8c8c8c"
  }
}));
export interface DropdownItem {
  label: string;
  handleClick?: (option: string) => void;
  icon?: JSX.Element;
  disabled?: boolean;
}

interface DropdownButtonProps {
  buttonLabel: string;
  options: string[] | DropdownItem[];
  selectedOption: string;
  handleClick?: (option: string) => void;
  icon?: JSX.Element;
  buttonId?: string;
  setSelectedOption?: Function;
  extraMenuItems?: DropdownItem[];
  className?: string;
  arrow?: boolean;
}

const adaptStringOptionsToMenuItems = (
  options: string[] | DropdownItem[]
): DropdownItem[] => {
  let result: DropdownItem[];
  if (options.every((v: string | DropdownItem) => typeof v === "string")) {
    result = (options as string[]).map((o: string) => ({
      label: o
    }));
  } else {
    result = options as DropdownItem[];
  }
  return result;
};

const DropdownButton = ({
  buttonLabel,
  options,
  handleClick,
  selectedOption,
  setSelectedOption,
  icon,
  buttonId,
  extraMenuItems,
  className,
  arrow = true
}: DropdownButtonProps) => {
  const classes = useStyles({});

  const [open, setOpen] = useState(false); // Ephemeral local state
  const anchorRef = useRef(null);

  const handleClickButtonGroup = useCallback(() => {
    if(buttonId !== "highlight") {
      setOpen(prevOpen => !prevOpen);
    }
    return handleClick && handleClick(selectedOption);
   },[buttonId, handleClick, selectedOption]);

  const handleClickButtonGroupArrow = useCallback(
    () => setOpen(prevOpen => !prevOpen),
    []
  );
  const handleClickAwayDropdown = useCallback(() => setOpen(false), []);
  const handleClickMenuItem = useCallback(
    (option: DropdownItem) => {
      if (setSelectedOption) {
        setSelectedOption(option.label);
      }
      setOpen(false);
    },
    [setSelectedOption]
  );

  const adaptedOptions = adaptStringOptionsToMenuItems(options);

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        aria-label="split button"
        className={classes.buttonGroup}
      >
        <Button
          id={buttonId}
          onClick={handleClickButtonGroup}
          className={
            classes.dropdownButton + (className ? " " + className : "")
          }
        >
          {icon}
          <Typography variant="caption" component="h2">
            {buttonLabel}
          </Typography>
        </Button>
        {arrow &&
          <Button
            className={
              classes.dropdownButtonArrow + (className ? " " + className : "")
            }
            size="small"
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleClickButtonGroupArrow}
          >
            <ArrowDropDownIcon />
          </Button>
        }
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClickAwayDropdown}>
                <MenuList className={classes.dropdownMenuList} disablePadding>
                  {adaptedOptions.map((option: DropdownItem) => (
                    <MenuItem
                      disabled={option.disabled}
                      key={option.label}
                      selected={option.label === selectedOption}
                      onClick={
                        option.handleClick
                          ? () => {
                              option.handleClick(option.label);
                              setOpen(false);
                            }
                          : () => handleClickMenuItem(option)
                      }
                    >
                      {option.icon && (
                        <ListItemIcon>{option.icon}</ListItemIcon>
                      )}
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                  {extraMenuItems && <Divider className={classes.divider} />}
                  {extraMenuItems &&
                    extraMenuItems.map(extra => (
                      <MenuItem
                        key={extra.label}
                        onClick={e => {
                          extra.handleClick(extra.label);
                          setOpen(false);
                        }}
                      >
                        {extra.icon && (
                          <ListItemIcon>{extra.icon}</ListItemIcon>
                        )}
                        <ListItemText primary={extra.label} />
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownButton;
