import React from 'react';
import { Box, Theme, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    zIndex: theme.zIndex.modal - 1,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    textAlign: 'center'
  },
  icon: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    color: '#1565c0'
  }
}));

interface LoadingProps {
  id?: string;
  display?: boolean;
}

const Loading = ({ id, display }: LoadingProps) => {
  const classes = useStyles({});
  return (
    <Box id={id} className={classes.container} style={{ display: display ? 'block' : 'none' }}>
      <CircularProgress className={classes.icon} size={40} />
    </Box>
  );
};

export default Loading;
