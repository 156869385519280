import {
  SurferActionTypes,
  SET_PLAYBACK_RATE,
  SurferState,
  SET_DURATION,
  SET_TIME,
  SET_PAUSED
} from "../types/surferTypes";

export const defaultState: SurferState = {
  playbackRate: 1,
  paused: true,
  duration: 0,
  time: 0
};

export function surfer(state = defaultState, action: SurferActionTypes) {
  switch (action.type) {
    case SET_PLAYBACK_RATE:
      return {
        ...state,
        playbackRate: action.rate
      };
    case SET_DURATION:
      return {
        ...state,
        duration: action.duration
      };
    case SET_TIME:
      return {
        ...state,
        time: action.time
      };
    case SET_PAUSED:
      return {
        ...state,
        paused: action.paused
      };
    default:
      return {
        ...state
      };
  }
}
